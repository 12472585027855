
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class EtiquetaProduto extends Vue{
    @Prop() codigo!: string;
    @Prop() codigoChina!: string;
    @Prop() ano!: number;
    @Prop() nomeDescricao!: string;
    @Prop() dataAtualizacao!: string;
}
