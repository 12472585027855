import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class CertificadoService extends Service {
    
    constructor() {
        super('v1/certificado');
    }

    private MontaFiltro(parametros: string, filter: any){
        if(filter){
            let paramTemp = '';

            if(filter.numeroInicio){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `numero ge (${filter.numeroInicio})`;
            }

            if(filter.numeroFim){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `numero le (${filter.numeroFim})`;
            }

            if(filter.pessoaId){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `pessoaId eq (${filter.pessoaId})`;
            }

            if(filter.orgaoCertificadorId){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `orgaoCertificadorId eq (${filter.orgaoCertificadorId})`;
            }

            if(filter.dataValidadeInicio){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `dataValidade ge (${filter.dataValidadeInicio})`;
            }

            if(filter.dataValidadeFim){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `dataValidade le (${filter.dataValidadeFim})`;
            }

            if (paramTemp) {

                if (parametros) {
                    parametros += '&';
                }
                else {
                    parametros += '?';
                }

                parametros += `$filter=${paramTemp}`;
            }
        }

        return parametros;
    }

    ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
    
        parametros = this.MontaFiltro(parametros, filter);
    
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }    
}