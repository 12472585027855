
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { CrudBase, Estado, Municipio, Pais } from '@/core/models/shared';
import { EnderecoService, EntidadeLegalService } from '@/core/services/finder'
import { Pessoa, Produto, ProdutoCliente } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { PessoaFisica, PessoaJuridica } from '@/core/models/shared/PessoaBase';

@Component
export default class CadastroPessoa extends CrudBase{
    @Prop() item!: Pessoa;
    @Prop() value!: string;

    gedFoto: any = this.GEDProduto();

    itemOriginal!: Pessoa;
    service: PessoaService = new PessoaService();
    $refs!: {
        form: HTMLFormElement,
        formGrupo: HTMLFormElement
    }

    fisicaJuridica: any[] = [
        { id: true, nome: "Juridica"},
        { id: false, nome: "Física"}
    ]
    juridicaAux: boolean = false;

    layouts: any = [
        { nome: "Layout 1", valor: 1 },
        { nome: "Layout 2", valor: 2 },
        { nome: "Layout 3", valor: 3 },
        { nome: "Layout 4", valor: 4 }
    ]

    paises: Pais[] = [];
    estadoId: number = 0;    
    estados: Estado[] = [];
    municipios: Municipio[] = [];
    enderecoService: EnderecoService = new EnderecoService();
    entidadeLegalService: EntidadeLegalService = new EntidadeLegalService();

    logo: any = null;    

    produtos: Produto[] = [];
    produtoCliente: ProdutoCliente = new ProdutoCliente();    
    editaProdutoCliente: boolean = false;
    dialogProdutoCliente: boolean = false;
    produtoHeader: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Foto', value: 'produto.foto' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Marca', value: 'marca' },
        { text: 'Codigo', value: 'codigo' },
        { text: 'Ean', value: 'ean' },
        { text: 'Ean Inner', value: 'eanInner' },
        { text: 'Ean Master', value: 'eanMaster' },
        { text: 'Ncm', value: 'ncm' },
    ];

    validGrupo: boolean = false;
    onSearchPessoa: any = null;
    isPessoaLoading: boolean = false;
    pessoaId: number = 0;
    pessoas: Pessoa[] = [];
    grupos: Pessoa[] = [];
    agrupadoHeaders: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'CPF/CNPJ', value:'cpfCnpj', sortable: false, type:'boolean', use: true },
        { text: 'Nome', value: 'nome', use: true },
        { text: 'Telefone 1', value: 'telefone1', use: true },
        { text: 'E-mail', value: 'email', use: true },
        { text: 'Situação', value: 'ativo', type: 'boolean', use: true }
    ];

    searchProduto: string = '';
    produtosListagem: ProdutoCliente[] = [];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);            
            this.estadoId = this.item.endereco.municipio.estadoId;
            this.produtosListagem = this.item.produtos;
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    @Watch("item.isJuridica", {deep: true})
    WatchJuridica(){
        this.juridicaAux = this.item.isJuridica;
        if(this.item.isJuridica){
            this.item.juridica = new PessoaJuridica(this.item.juridica);            
            this.item.fisica = undefined;
        }
        else{
            this.item.fisica = new PessoaFisica(this.item.fisica);
            this.item.juridica = undefined;
        }
    }

    @Watch("item.endereco.paisId")
    WatchPais(){
        if(this.item.endereco.paisId != 1058){
            this.estadoId = 99;
            this.item.endereco.municipioId = 9999999;            
            this.item.fisica = undefined;
            this.item.juridica = undefined;
        }
    }

    @Watch('onSearchPessoa')
    searchCliente (val: string) {
        if (this.pessoaId > 0) return;
        if (this.isPessoaLoading) return;
        if (!val) return;
        this.isPessoaLoading = true
        this.service.AutoComplete(val, "c").then(
            res => {
                this.pessoas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isPessoaLoading = false
        });
    }

    @Watch('searchProduto')
    WatchSearchProduto(val: string){
        if(val){
            this.produtosListagem = this.item.produtos.filter(function(item){
                if(item.descricao && item.descricao.includes(val))
                    return item;
                if(item.marca && item.marca.includes(val))
                    return item;
                if(item.codigo.includes(val))
                    return item;
                if(item.ean && item.ean.includes(val))
                    return item;
                if(item.eanInner && item.eanInner.includes(val))
                    return item;
                if(item.eanMaster && item.eanMaster.includes(val))
                    return item;
                if(item.ncm && item.ncm.includes(val))
                    return item;
            });
        }
        else {
            this.produtosListagem = this.item.produtos;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.enderecoService.ObterPaises().then(
            res => {
                this.paises = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );        
        this.service.ListarGrupos(this.item.id).then(
            res => {
                this.grupos = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        )        
    }

    CarregarMunicipio(estadoId: number){
        this.enderecoService.ObterMunicipios(estadoId).then(
            res => {
                this.municipios = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    ObterEndereco(cep: string){
        if(this.item.endereco.paisId == 1058){
            this.loading = true;
            this.enderecoService.ObterPorCep(cep).then(
                res => {
                    const endereco = res.data;
                    if (endereco) {
                        this.item.endereco.logradouro = endereco.logradouro;
                        this.item.endereco.bairro = endereco.bairro;
                        this.estadoId = endereco.estadoId;
                        this.item.endereco.municipioId = endereco.municipioId;
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            });
        }
    }

    ObterEntidadeLegal(cnpj: string){
        this.loading = true;
        this.entidadeLegalService.ObterPorCnpj(cnpj).then(
            res => {
                const entidade = res.data;
                if (entidade) {
                    this.item.nome = entidade.nome;
                    this.item.juridica!.nomeFantasia = entidade.nomeFantasia;
                    this.item.endereco.cep = entidade.endereco.cep;
                    this.item.endereco.logradouro = entidade.endereco.logradouro;
                    this.item.endereco.numero = entidade.endereco.numero;
                    this.item.endereco.complemento = entidade.endereco.complemento;
                    this.item.endereco.bairro = entidade.endereco.bairro;
                    this.estadoId = entidade.endereco.estadoId;
                    this.item.endereco.municipioId = entidade.endereco.municipioId;
                    this.item.email = entidade.email;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        });
    }

    AbrirDialogProdutoCliente(item?){
        if(item){
            this.produtoCliente = item;
            this.editaProdutoCliente = true;
        }
        else{
            this.produtoCliente = new ProdutoCliente();
            this.produtoCliente.pessoaId = this.item.id;
            this.editaProdutoCliente = false;
        }
        this.dialogProdutoCliente = true;
    }

    ExcluirProdutoCliente(item: ProdutoCliente){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.produtos.indexOf(item);
                context.item.produtos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    SalvarProdutoCliente(){
        if(!this.editaProdutoCliente)
            this.item.produtos.push(this.produtoCliente);
    }

    AdicionarPessoa(){
        if (this.$refs.formGrupo.validate()) {
            let pessoa = this.pessoas.find(x => x.id == this.pessoaId)!;
            pessoa.agrupadorId = this.item.id;
            this.grupos.push(pessoa);
        }
    }

    ExcluirPessoa(item: Pessoa){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let pessoaExcluir = context.grupos.find(x => x.id == item.id)!;
                pessoaExcluir.agrupadorId = -1;
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            if(this.item.isCliente || this.item.isExportador || this.item.isImportador || this.item.isDistribuidor || this.item.isFabricante || this.grupos.length > 0 ){
                //QUANDO FOR GRUPO PRECISA SER FIXO NO LAYOUT 2
                if(this.grupos.length > 0){
                    this.item.piLayout = 2;
                }
                this.loading = true;
                let patchModel = jiff.diff(this.itemOriginal, this.item, false);
                (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                    res => {
                        let id = res.data.id ? res.data.id : this.item.id;                        
                        if(this.grupos.length > 0){
                            this.grupos.forEach((x, index) => {
                                x.agrupadorId == -1 ? 0 : x.agrupadorId = id;
                                this.service.VincularAgrupador(x.id, x.agrupadorId).then(
                                    res => {
                                        if(this.grupos.length - 1 == index){
                                            AlertSimpleRes("Aviso!", res);
                                            this.$emit("salvou");
                                            this.Close();
                                        }
                                    },
                                    err => AlertSimpleErr("Aviso!", err)
                                )
                            })
                        }
                        else{
                            AlertSimpleRes("Aviso", res);
                            this.$emit("salvou");
                            this.Close();
                        }                                              
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    this.loading = false;
                })
            }
            else{
                AlertSimple("Aviso!", "É necessário selecionar pelo menos um tipo de pessoa ou dizer quais pessoas compõe!", "warning");
            }
        }
    }

    Close(){
        this.Limpar();
        this.dialog = false;
    }

    Limpar(){
        this.estadoId = 0;
        this.item.isJuridica = this.juridicaAux;
    }
}
