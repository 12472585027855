
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { PedidoItem } from '@/core/models/geral';
import { EmbarqueService } from '@/core/services/geral/EmbarqueService';
import { AlertSimpleErr } from '@/core/services/shared';

@Component
export default class CadastroEmbarqueItem extends CrudBase {
    @Prop() private value!: string;
    @Prop() selected!: PedidoItem[];
    @Prop() item!: PedidoItem;    
    @Prop() pedidoId!: number;

    gedFoto: any = this.GEDProduto();

    dialog: boolean = false;
    lista: any[] = [];
    embarqueService: EmbarqueService = new EmbarqueService();
    localSelected: PedidoItem[] = [];
    loading: boolean = false;
    headers: any[] = [
        { text: 'Cod. China', value: 'produto.codigoChina' },
        { text: 'Foto', value: 'produto.sampleFoto', sortable: false },
        { text: 'Cod. Cliente', value: 'produtoCliente.codigo' },
        { text: 'Saldo', value: 'saldo' }
    ];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.localSelected = [];
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        
    }

    Carregar(){
        this.loading = true
        this.embarqueService.ItensSemEmbarque(this.pedidoId).then(
            res => {
                this.lista = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        })
    }

    Selecionar(){
        this.$emit('update:selected', this.localSelected);
        if(this.localSelected.length > 0)
            this.$emit('selecionou');
        this.Close();
    }

    Close(){
        this.$emit("fechou");
        this.dialog = false;
    }
}
