import { PessoaBase } from "../shared/PessoaBase";
import { ProdutoCliente } from "./ProdutoCliente";

export class Pessoa extends PessoaBase implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    agrupadorId?: number;
    agrupador!: Pessoa;
    percentual: number = 0;
    fatorCusto: number = 0;
    fatorVenda: number = 0;
    bankInfo: string = "";
    prefixo: string = "";
    piLayout?: number;

    isCliente: boolean = false;
    isExportador: boolean = false;
    isImportador: boolean = false;
    isDistribuidor: boolean = false;
    isFabricante: boolean = false;
    isGrupo: boolean = false;

    produtos: ProdutoCliente[] = [];
    grupos: Pessoa[] = [];

    constructor(model?: Pessoa){

        super(model);

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.agrupadorId = model.agrupadorId;
        this.agrupador = model.agrupador;
        this.percentual = model.percentual;
        this.fatorCusto = model.fatorCusto;
        this.fatorVenda = model.fatorVenda;
        this.bankInfo = model.bankInfo;
        this.prefixo = model.prefixo;
        this.piLayout = model.piLayout;

        this.isCliente = model.isCliente;
        this.isExportador = model.isExportador;
        this.isImportador = model.isImportador;
        this.isDistribuidor = model.isDistribuidor;
        this.isFabricante = model.isFabricante;
        this.isGrupo = model.isGrupo;

        this.produtos = model.produtos;
        this.grupos = model.grupos;
    }
}