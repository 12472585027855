import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class CategoriaProdutoService extends Service {
    
    constructor() {
        super('v1/categoriaProduto');
    }

    private GetFiltro(parametros: string, filter: any){

        if (filter) {
          let paramTemp = '';
    
          if(filter.id){
            if(paramTemp){paramTemp += ' and '; }
            paramTemp += `id eq ${filter.id}`;
          } 
    
          if (paramTemp) {
    
            if(parametros){
              parametros += '&';
            }
            else {
              parametros += '?';
            }
    
            parametros += `$filter=${paramTemp}`;
          }
        }
      
        return parametros;  
      }

      GetByIds(ids: string){
        return http.get(`${this.GetNomeControle()}/GetByIds`, {
          params: {
            ids
          }
        });
      }
    
      TreeView(){
        return http.get(`${this.GetNomeControle()}/treeview`);
      }
    
      Listagem(){
        return http.get(`${this.GetNomeControle()}/listagem`);
      }
      
      public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
      } 
    
}