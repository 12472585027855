import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class EmpresaCronogramaEtapaService extends Service {
    
    constructor() {
        super('v1/empresaCronogramaEtapa');
    }
    
    AlterarTempo(id: number, tempo: number){
        return http.put(`${this.GetNomeControle()}/${id}/AlterarTempo/${tempo}`);
    }

}