
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase, Operacao } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertQuestion, AlertSimpleErr, AlertSimpleNotification, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Agendamento, Container, Embarque, EmbarqueItem, Pedido, PedidoItem, Pessoa, TipoAndamentoPedido, TipoSelecaoPedidoItem } from '@/core/models/geral';
import { AgendamentoService, ContainerService, PedidoService, PessoaService, TipoAndamentoPedidoService, TipoSelecaoPedidoItemService } from '@/core/services/geral';
import { EmbarqueService } from '@/core/services/geral/EmbarqueService';

@Component
export default class CadastroTipoEmbalagem extends CrudBase{
    @Prop() id!: number;
    @Prop() private value!: string;

    gedFoto: any = this.GEDProduto();

    item: Pedido = new Pedido();
    itemOriginal!: Pedido;
    service: PedidoService = new PedidoService();
    $refs!: {
        form: HTMLFormElement
    }

    clienteFatorCusto: number = 0;
    clienteFatorVenda: number = 0;

    pessoaService: PessoaService = new PessoaService();    
    
    importadores: Pessoa[] = [];
    onSearchImportador: any = null;
    isImportadorLoading: boolean = false;
    
    distribuidores: Pessoa[] = [];
    onSearchDistribuidores: any = null;
    isDistribuidorLoading: boolean = false;

    andamentos: TipoAndamentoPedido[] = [];
    andamentoService: TipoAndamentoPedidoService = new TipoAndamentoPedidoService();

    agendamento: Agendamento = new Agendamento();
    dialogCadastroAgendamento: boolean = false;
    agendamentos: any[] = [];
    agendamentoService: AgendamentoService = new AgendamentoService();

    cronogramaItens: PedidoItem[] = [];
    loadingCronogramaItens: boolean = false;
    cronogramaItensHeaders: any[] = [
        { text: 'Cod. China',   value: 'produto.codigoChina' },
        { text: 'Observação',   value: 'observacao' },
        { text: 'Cod. Cliente', value: 'produtoCodigo' },
        { text: 'Marketing',    value: 'cronograma.marketing' },
        { text: 'Rotulagem',    value: 'cronograma.rotulagem', },
        { text: 'Certificação', value: 'cronograma.certificacao' },
        { text: 'Fabricação',   value: 'cronograma.fabricacao' },
        { text: 'Inspeção',     value: 'cronograma.inspecao' },
        { text: 'Embarque',     value: 'cronograma.embarque' },
        { text: 'Prontidão',    value: 'cronograma.prontidao' }
    ]

    filtro: any = {
        tipoItem: 1,
        marketing: false
    }
    enumTipoItem: any[] = [
        { id: 1, nome: "TODOS"},
        { id: 2, nome: "SELECIONADOS"},
        { id: 3, nome: "NÃO SELECIONADOS"},
    ];
    pedidoItens: PedidoItem[] = [];
    disableTipoItem: boolean = false;
    pedidoItemCancelarId: number = 0;
    dialogPedidoItemCancelar: boolean = false;   
    pedidoItemCancelarJustificativa: string = ""; 

    dialogCadastroPedidoItem: boolean = false;
    editaItem: boolean = false;
    pedidoItem: PedidoItem = new PedidoItem();
    headerItens: any[] = [];

    containers: Container[] = [];
    containerService: ContainerService = new ContainerService();

    tipoSelecaoService: TipoSelecaoPedidoItemService = new TipoSelecaoPedidoItemService();
    tipoSelecoes: TipoSelecaoPedidoItem[] = [];
    tipoSelecoesDisabilitadas: TipoSelecaoPedidoItem[] = [];

    metricasEmbarques: any = {
        containerEmbarcado: 0,
        containerNaoEmbarcado: 0,
        containerTotal: 0,
        cubagemEmbarcado: 0,
        cubagemNaoEmbarcado: 0,
        cubagemTotal: 0,
        valorEmbarcado: 0,
        valorNaoEmbarcado: 0,
        valorTotal: 0
    };

    itensSemEmbarque: PedidoItem[] = [];
    itensSemEmbarqueHeaders: any[] = [
        { text: 'Cod. China', value: 'produto.codigoChina' },
        { text: 'Foto', value: 'produto.foto', sortable: false },
        { text: 'Cod. Cliente', value: 'produtoCliente.codigo' },
        { text: 'Saldo', value: 'saldo' }
    ]

    idDetalharEmbarque: number = 0;
    relatorioDialogEmbarque: boolean = false;
    routeDetalharEmbarque: string = "DetalharPi";
    embarques: Embarque[] = [];
    embarqueService: EmbarqueService = new EmbarqueService();
    embarqueId: number = -1;
    embarqueDesmembrarId: number = 0;
    embarqueClonarId: number = 0;
    dialogEmbarqueCadastro: boolean = false;
    embarqueHeader: any[] = [
        { text: '', value: 'actions' },
        { text: 'Data', value: 'data' },
        { text: 'Evento', value: 'evento' },
        { text: 'Invoice', value: 'invoice' },
        { text: 'PO', value: 'po' },
        { text: 'Container', value: 'container.nome' },
        { text: 'QTD. Container', value: 'cbmContainerTotal', sortable: false },
        { text: 'Cubagem Total', value: 'cbmTotal', sortable: false },
        { text: 'Valor Total', value: 'valorTotal', sortable: false },
        { text: 'Porto Carga', value: 'portoCarga.nome' },
        { text: 'Porto Descarga', value: 'portoDescarga.nome' },
        { text: 'BL', value: 'bl' },
        { text: 'Data BL', value: 'dataLancamentoBL' }
    ]

    salvandoAutomatico: boolean = false;

    itemClass(item: any){
        if(item.cancelado)
            return 'cancelado'
    }

    embarqueClass(item: any){
        if(item.desmembramentoId && !item.desmembrado)
            return 'alerta'
    }

    @Watch("filtro.marketing")
    WatchPedidoHeader(){
        if(this.filtro.marketing){
            this.filtro.tipoItem = 2;
            this.disableTipoItem = true;
            this.headerItens = [
                { text: 'Cod. China', value: 'produto.codigoChina' },
                { text: 'Foto', value: 'produto.foto', sortable: false },
                { text: 'Nome', value: 'produtoCliente.descricao' },
                { text: 'Cod. Cliente', value: 'produtoCodigo'},
                { text: 'CBM. UNIT', value: 'produtoCliente.cubagemUnitaria'},
                { text: 'CX.Inner', value: 'produtoCliente.cxInner'},
                { text: 'CX.Master', value: 'produtoCliente.cxMaster'},
                { text: 'Ean', value: 'produtoCliente.ean'},
                { text: 'Ean Inner', value: 'produtoCliente.eanInner'},
                { text: 'Ean Master', value: 'produtoCliente.eanMaster'},
                { text: 'Ncm', value: 'produtoCliente.ncm'},
                { text: 'MOQ', value: 'quantidade'},
                { text: 'Qtd. Informada', value: 'quantidadeInformada'},
                { text: 'Observação', value: 'observacao'}
            ];
        }
        else{
            this.filtro.tipoItem = 1;
            this.disableTipoItem = false;
            this.headerItens = [
                { text: '',value:'actions' ,sortable: false, class: 'action'},
                { text: 'Cod. China', value: 'produto.codigoChina' },
                { text: 'Foto', value: 'produto.foto', sortable: false },
                { text: 'Cod.', value: 'produto.codigo'},
                { text: 'Cod. Cliente', value: 'produtoCodigo'},
                { text: 'Descrição', value: 'produto.descricao'},
                { text: 'Categoria.', value: 'produto.categoria.codigoNome'},
                { text: 'Ano', value: 'produto.ano'},
                { text: 'Seleção', value: 'tipoSelecao'},
                { text: 'FOB', value: 'valor'},
                { text: 'Fator Venda', value: 'fatorVenda'},
                { text: 'Fator Custo', value: 'fatorCusto'},
                { text: 'MOQ', value: 'quantidade'},
                { text: 'Qtd. Informada', value: 'quantidadeInformada'},
                { text: 'Observação', value: 'observacao'}
            ];
        }        
    }
    
    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    @Watch('id')
    WatchId(){
        if(this.id > 0){
            this.loading = true;
            this.service.ObterPorId(this.id, "Agendamento.Cliente, Importador, Distribuidor, Itens.Cliente, Itens.Produto.Categoria, Itens.ProdutoCliente").then(
                res => {
                    this.item = res.data;
                    this.item.data = this.item.data.toDateYYYYMMDD();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
        else{
            this.item = new Pedido();
        }
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            this.WatchPedidoHeader();
        }
        else {
            this.$emit("fechou");            
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);            
            
            if(this.item.importadorId)
                this.importadores.push(this.item.importador);
            
            if(this.item.distribuidorId)
                this.distribuidores.push(this.item.distribuidor);

            this.PedidoItemFilter();
            this.WatchPedidoHeader();
            this.CarregarEmbarques();
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('onSearchImportador')
    searchImportador (val: string) {
        if (this.item.importadorId) return;
        if (this.isImportadorLoading) return;
        if (!val) return;
        this.isImportadorLoading = true
        this.pessoaService.AutoComplete(val, "i").then(
            res => {
                this.importadores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isImportadorLoading = false
        });
    }

    @Watch('onSearchDistribuidores')
    searchDistribuidor (val: string) {
        if (this.item.distribuidorId) return;
        if (this.isDistribuidorLoading) return;
        if (!val) return;
        this.isDistribuidorLoading = true
        this.pessoaService.AutoComplete(val, "d").then(
            res => {
                this.distribuidores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isDistribuidorLoading = false
        });
    }

    @Watch("item.agendamentoId")
    WatchAgendamento(){
        if(this.item.agendamentoId && !(this.item.id > 0)){
            this.item.agendamento = this.agendamentos.find(x => x.id == this.item.agendamentoId)!;
        }
        if(this.item.agendamentoId > 0){
            if(this.item.agendamento.cliente){
                this.clienteFatorCusto = this.item.agendamento.cliente.fatorCusto;
                this.clienteFatorVenda = this.item.agendamento.cliente.fatorVenda;
            }
        }
    }

    @Watch("filtro.tipoItem")
    PedidoItemFilter(){
        if(this.filtro.tipoItem == 1)
            this.pedidoItens = this.item.itens;
        else if(this.filtro.tipoItem == 2)
            this.pedidoItens = this.item.itens.filter(x => x.quantidadeInformada > 0);
        else
            this.pedidoItens = this.item.itens.filter(x => x.quantidadeInformada <= 0);
    }

    VerificarQuantidadeInformada(item: PedidoItem, val: any){
        if(!val || val.length == 0){
            item.quantidadeInformada = 0;
        }                
    }

    HabilitarItens(){
        return this.item.agendamentoId > 0 && this.item.identificacao && this.item.containerId;
    }

    ToUpper(val: string){
        this.item.identificacao = val.toUpperCase().replace(/[ÀÁÂÃÄÅ]/g,"A").replace(/[àáâãäå]/g,"a").replace(/[ÈÉÊË]/g,"E").replace(/[ç]/g,"c").replace(/[Ç]/g,"C");
    }

    DbClickPedidoItem(val: any, {item}: any){
        if(item && item.id > 0)
            this.DialogCadastroPedidoItem(item);
    }

    DbClickEmbarque(val: any, {item}: any){
        if(item && item.id > 0)
            this.DialogCadastroEmbarque(item);
    }

    Carregar(){
        this.andamentoService.ListarTudo().then(
            res => {
                this.andamentos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.CarregarColocarAgendamentos();
        this.containerService.ListarTudo().then(
            res => {
                this.containers = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.tipoSelecaoService.ListarTudo().then(
            res => {
                this.tipoSelecoes = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    CarregarColocarAgendamentos(id?: number){
        this.agendamentoService.ListarTudo("Cliente").then(
            res => {
                this.agendamentos = res.data.items;
                if(id){
                    this.item.agendamentoId = id;
                    this.WatchAgendamento();
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    DialogAgendamento(){
        this.agendamento = new Agendamento();
        this.dialogCadastroAgendamento = true;
    }

    DialogCadastroPedidoItem(item?: PedidoItem){
        if(item){
            this.editaItem = true;
            this.pedidoItem = item;
        }
        else{
            this.editaItem = false;
            this.pedidoItem = new PedidoItem();
            this.pedidoItem.clienteId = this.item.agendamento.clienteId;
            this.pedidoItem.cliente = this.item.agendamento.cliente!;
            this.pedidoItem.pedidoId = this.item.id;
        }
        this.dialogCadastroPedidoItem = true;
    }

    SalvarPedidoItem(){
        if(!this.editaItem){
            this.tipoSelecoes.forEach(x => {
                x['disable'] = false;
            })
            if(this.pedidoItem.ultimoPedidoId){
                if(this.pedidoItem.observacao.length > 0){
                    this.pedidoItem.tipoSelecaoId = 4;
                    //DESABILITAR
                    [1, 2, 3, 5].forEach(x => {
                        this.tipoSelecoes.find(y => y.id == x)!['disable'] = true;
                    });                    
                }
                else{
                    this.pedidoItem.tipoSelecaoId = 3;
                    //DESABILITAR
                    [1, 2, 4, 5].forEach(x => {
                        this.tipoSelecoes.find(y => y.id == x)!['disable'] = true;
                    });
                }
            }
            else{
                if(this.pedidoItem.observacao.length > 0){
                    this.pedidoItem.tipoSelecaoId = 2;
                    //DESABILITAR
                    [1, 3, 4, 6].forEach(x => {
                        this.tipoSelecoes.find(y => y.id == x)!['disable'] = true;
                    });
                }
                else{
                    this.pedidoItem.tipoSelecaoId = 1;
                    //DESABILITAR
                    [2, 3, 4, 6].forEach(x => {
                        this.tipoSelecoes.find(y => y.id == x)!['disable'] = true;
                    });
                }
            }
            this.item.itens.push(this.pedidoItem);
            setTimeout(() => {
                this.SalvarAutomatico();
            }, 500);
        }
        this.PedidoItemFilter();
    }

    CancelarPedidoItem(itemId: number, cancelar: boolean = false){
        if(cancelar){
            this.service.CancelarItem(this.item.id, this.pedidoItemCancelarId, this.pedidoItemCancelarJustificativa).then(
                res => {
                    let cancelamento = res.data;
                    let item = this.item.itens.find(x => x.id == this.pedidoItemCancelarId)!;
                    item.cancelado = true;
                    item.cancelamento = cancelamento;                    
                    AlertSimpleRes("Aviso!", res);
                    this.VoltarPedidoItemCancelar();
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else{
            this.pedidoItemCancelarId = itemId;
            this.dialogPedidoItemCancelar = true;
        }
    }

    ReabrirPedidoItem(itemId: number){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let item = context.item.itens.find(x => x.id == itemId)!;                
                item.cancelado = false;
                item.cancelamento = undefined;
                // context.service.ReabrirItem(context.item.id, itemId).then(
                //     res => {
                //         let item = context.item.itens.find(x => x.id == context.pedidoItemCancelarId)!;
                //         item.cancelado = false;
                //         item.cancelamento = undefined;
                //         AlertSimpleRes("Aviso", res);
                //     },
                //     err => AlertSimpleErr("Aviso", err)
                // )
            });
        }
        AlertQuestion("Aviso!", "Tem certeza que deseja reabrir o registro ?", excluir);
    }

    VoltarPedidoItemCancelar(){
        this.pedidoItemCancelarJustificativa = "";
        this.pedidoItemCancelarId = 0;
        this.dialogPedidoItemCancelar = false;
    }

    ExcluirPedidoItem(item: PedidoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true).finally(() => {
            context.SalvarAutomatico();
        });
    }

    ListarItensCronograma(){
        // this.loadingCronogramaItens = true;
        // this.service.ListarItensCronograma(this.item.id).then(
        //     res => {
        //         this.cronogramaItens = res.data;
        //     },
        //     err => AlertSimpleErr("Aviso!", err)
        // ).finally(() => {
        //     this.loadingCronogramaItens = false;
        // })
    }

    SetarItemCronogramaEtapa(pedidoItemId: number, realizado: boolean, etapaId: number){
        // this.service.SetarItemCronogramaEtapa(pedidoItemId, realizado, etapaId).then(
        //     res => {
        //         AlertSimpleNotification("Salvando...", "success");
        //         this.ListarItensCronograma();
        //     },
        //     err => AlertSimpleErr("Aviso!", err)
        // );
    }

    DialogCadastroEmbarque(item?: Embarque){
        this.embarqueId = item ? item.id : 0;
        this.dialogEmbarqueCadastro = true;
    }

    CarregarEmbarques(){
        this.loading = true;
        this.embarqueService.ListarTudoFiltro(`pedidoId eq ${this.item.id}`, '', "Cliente, PortoCarga, PortoDescarga, Exportador, Container").then(
            res => {
                this.embarques = res.data.items;
                this.embarques.forEach(embarque => {
                    this.embarqueService.Metricas(embarque.id).then(
                        res => {
                            let retorno = res.data;                            
                            embarque[`cbmTotal`] = retorno.cbmTotal;
                            embarque[`valorTotal`] = retorno.valorTotal;
                            embarque[`cbmContainerTotal`] = retorno.cbmContainerTotal;
                            this.AtualizarTotalizadorEmbarques();
                        }
                    )
                });
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        })
        this.embarqueService.ItensSemEmbarque(this.item.id).then(
            res => {
                this.itensSemEmbarque = res.data;
                if(this.itensSemEmbarque.length > 0 && !this.salvandoAutomatico)
                    this.tabActive = 3;
            },
            err => AlertSimpleErr("Aviso!", err)
        )        
    }

    FechouEmbarque(){
        if(this.dialogEmbarqueCadastro){
            this.dialogEmbarqueCadastro = false;
            this.embarqueDesmembrarId = 0;
            this.embarqueId = -1;
            this.AtualizarEmbarque();
        }
    }

    AbrirJanelaDetalharEmbarque(item?: Embarque, rota?: string) {        
        if(item){
            this.idDetalharEmbarque = item.id;
            if(this.item.agendamento.cliente?.isGrupo){
                this.AbrirJanelaDetalharEmbarque(undefined, "DetalharPi2");
            }
            else if(this.item.agendamento.cliente?.piLayout){
                switch(this.item.agendamento.cliente?.piLayout){
                    case 1:
                        this.AbrirJanelaDetalharEmbarque(undefined, "DetalharPi");
                        break;
                    case 2:
                        this.AbrirJanelaDetalharEmbarque(undefined, "DetalharPi2");
                        break;
                    case 3:
                        this.AbrirJanelaDetalharEmbarque(undefined, "DetalharPi3");
                        break;
                    case 4:
                        this.AbrirJanelaDetalharEmbarque(undefined, "DetalharPi4");
                        break;
                }
            }
            else{
                this.relatorioDialogEmbarque = true;
            } 
        }
        
        if(rota){
            let routeLink = this.$router.resolve({ name: rota, params: { id: this.idDetalharEmbarque.toString() } });
            window.open(routeLink.href, '_blank');
        }        
    }

    GerarPackingList(item: Embarque){
        this.embarqueService.PackingList(item.id).then(
            res=> {
                let file = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                let fileURL = URL.createObjectURL(file);
                let fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.download = `${item.exportadorId ? item.exportador.prefixo : ""}${item.invoice}.xlsx`;
                fileLink.click();
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AtualizarTotalizadorEmbarques(){
        this.metricasEmbarques.containerEmbarcado = 0;
        this.metricasEmbarques.containerNaoEmbarcado = 0;
        this.metricasEmbarques.containerTotal = 0;
        this.metricasEmbarques.cubagemEmbarcado = 0;
        this.metricasEmbarques.cubagemNaoEmbarcado = 0;
        this.metricasEmbarques.cubagemTotal = 0;
        this.metricasEmbarques.valorEmbarcado = 0;
        this.metricasEmbarques.valorNaoEmbarcado = 0;
        this.metricasEmbarques.valorTotal = 0;
        setTimeout(() => {
            this.embarques.forEach(embarque => {
                
                this.metricasEmbarques.containerEmbarcado = this.embarques.filter(x => x.dataLancamentoBL != null).reduce((a, b) => a + b[`cbmContainerTotal`], 0);
                this.metricasEmbarques.cubagemEmbarcado = this.embarques.filter(x => x.dataLancamentoBL != null).reduce((a, b) => a + b[`cbmTotal`], 0);
                this.metricasEmbarques.valorEmbarcado = this.embarques.filter(x => x.dataLancamentoBL != null).reduce((a, b) => a + b[`valorTotal`], 0);

                this.metricasEmbarques.containerNaoEmbarcado = this.embarques.filter(x => x.dataLancamentoBL == null).reduce((a, b) => a + b[`cbmContainerTotal`], 0);
                this.metricasEmbarques.cubagemNaoEmbarcado = this.embarques.filter(x => x.dataLancamentoBL == null).reduce((a, b) => a + b[`cbmTotal`], 0);
                this.metricasEmbarques.valorNaoEmbarcado = this.embarques.filter(x => x.dataLancamentoBL == null).reduce((a, b) => a + b[`valorTotal`], 0);

                this.metricasEmbarques.containerTotal = this.metricasEmbarques.containerEmbarcado + this.metricasEmbarques.containerNaoEmbarcado;
                this.metricasEmbarques.cubagemTotal = this.metricasEmbarques.cubagemEmbarcado + this.metricasEmbarques.cubagemNaoEmbarcado;
                this.metricasEmbarques.valorTotal = this.metricasEmbarques.valorEmbarcado + this.metricasEmbarques.valorNaoEmbarcado;
            });
        }, 1000);
    }

    AtualizarEmbarque(){
        this.embarques = [];
        this.embarqueDesmembrarId = 0;
        this.CarregarEmbarques();
    }

    ClonarEmbarque(item: Embarque){
        this.embarqueClonarId = item.id;        
        this.DialogCadastroEmbarque();
    }

    DesmembrarEmbarque(item: Embarque){
        this.embarqueDesmembrarId = item.id;
        this.DialogCadastroEmbarque();
    }

    ExcluirEmbarque(item: Embarque){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.embarqueService.Excluir(item.id).then(
                    res => {
                        context.AtualizarEmbarque();                        
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                )
            });
        }
        AlertExcludeQuestion(excluir);
    }

    SalvarAutomatico(){
        
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    if(this.item.itens.length > 0){
                        this.item.itens.forEach((item, index) => {
                            if(item['produtoCliente'] && item.quantidadeInformada > 0){
                                let produtoCliente = item['produtoCliente'];
                                new PessoaService().AtualizarDadosClienteProduto(produtoCliente.pessoaId, produtoCliente.produtoId, item.produtoCodigo!, produtoCliente.descricao, produtoCliente.codigo, produtoCliente.cubagemUnitaria, produtoCliente.cxInner, produtoCliente.cxMaster, produtoCliente.ean, produtoCliente.eanInner, produtoCliente.eanMaster, produtoCliente.ncm).then(
                                    res => {},
                                    err => AlertSimpleErr("Aviso!", err)
                                )
                            }
                            if(this.item.itens.length == index + 1){
                                AlertSimpleRes("Aviso", res);
                                this.$emit("salvou");
                                this.Close();
                            }
                        });
                    }
                    else {
                        AlertSimpleRes("Aviso", res);
                        this.$emit("salvou");
                        this.Close();
                    }
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.tabActive = 0;
        this.itensSemEmbarque = [];
        this.pedidoItens = [];
        this.salvandoAutomatico = false;
        this.dialog = false;
    }
}
