
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Certificado, CertificadoItem, OrgaoCertificador, Pessoa, ProdutoCliente } from '@/core/models/geral';
import { CertificadoService, OrgaoCertificadorService, PessoaService } from '@/core/services/geral';

@Component
export default class CadastroCertificado extends CrudBase{
    @Prop() private value!: string;
    @Prop() item!: Certificado;

    itemOriginal!: Certificado;
    service: CertificadoService = new CertificadoService();
    $refs!: {
        form: HTMLFormElement,
        formItem: HTMLFormElement
    }

    clienteService: PessoaService = new PessoaService();
    clientes: Pessoa[] = [];
    onSearchCliente: any = null;
    isClienteLoading: boolean = false;

    certificadorService: OrgaoCertificadorService = new OrgaoCertificadorService();
    certificadores: OrgaoCertificador[] = [];

    produtoSelecionado: ProdutoCliente = new ProdutoCliente();

    validProduto: boolean = false;
    pessoaService: PessoaService = new PessoaService();
    clienteProdutos: ProdutoCliente[] = [];
    certificadoItem: CertificadoItem = new CertificadoItem();
    headerCertificadoItens: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Cód.', value: 'produtoCliente.codigo', use: true },
        { text: 'Produto', value: 'produtoCliente.produto.nome', use: true },
        { text: 'Marca', value: 'produtoCliente.marca', use: true }
    ];


    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");            
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form)
            this.$refs.form.resetValidation();

        if (this.$refs.formItem)
            this.$refs.formItem.resetValidation();
        
        if(this.item.orgaoCertificadorId > 0)
            this.certificadores.push(this.item.orgaoCertificador);

        if(this.item.pessoaId > 0)
            this.clientes.push(this.item.pessoa);
    }

    @Watch('item.pessoaId')
    WatchCliente(){
        this.pessoaService.GetProdutos(this.item.pessoaId).then(
            res => {
                this.clienteProdutos = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        if (this.item.pessoaId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true;
        this.clienteService.AutoComplete(val, "c").then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isClienteLoading = false
        });
    }

    Carregar(){
        this.certificadorService.ListarTudo().then(
            res => {
                this.certificadores = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarCertificadoItem(){
        if(this.$refs.formItem.validate()){
            if(this.item.itens.find(x => x.fK_ProdutoId == this.produtoSelecionado.produtoId && x.produtoCodigo == this.produtoSelecionado.codigo)){
                AlertSimple("Aviso!", "O produto selecionado já está incluso nesse certificado!", "warning");
            }
            else{
                this.certificadoItem.certificadoId = this.item.id;
                this.certificadoItem.fK_ClienteId = this.item.pessoaId;
                this.certificadoItem.fK_ProdutoId = this.produtoSelecionado.produtoId;
                this.certificadoItem.produtoCodigo = this.produtoSelecionado.codigo;
                this.certificadoItem.produtoCliente = this.produtoSelecionado;
                
                this.item.itens.push(this.certificadoItem);
                
                this.certificadoItem = new CertificadoItem();

                if (this.$refs.formItem)
                    this.$refs.formItem.resetValidation();
            }
        }
    }

    ExcluirCertificadoItem(item: CertificadoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
}
