
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Agendamento, Pessoa } from '@/core/models/geral';
import { AgendamentoService, PessoaService } from '@/core/services/geral';

@Component
export default class CadastroAgendamento extends CrudBase{
    @Prop() private value!: string;
    @Prop() item!: Agendamento;

    itemOriginal!: Agendamento;
    service: AgendamentoService = new AgendamentoService();
    $refs!: {
        form: HTMLFormElement
    }

    clienteService: PessoaService = new PessoaService();
    clientes: Pessoa[] = [];
    onSearchCliente: any = null;
    isClienteLoading: boolean = false;

    usuarios: any[] = [];
    onSearchUsuario: any = null;
    isUsuarioLoading: boolean = false;
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.clientes.push(this.item.cliente!);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        if (this.item.clienteId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val, "c").then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isClienteLoading = false
        });
    }

    @Watch('onSearchUsuario')
    searchUsuario (val: string) {
        // if (this.item.usuarioId) return;
        // if (this.isUsuarioLoading) return;
        // if (!val) return;
        // this.isUsuarioLoading = true
        // this.clienteService.AutoComplete(val).then(
        //     res => {
        //         this.usuarios = res.data;
        //     },
        //     err => AlertSimpleErr("Aviso!", err)
        // ).finally(() => {
        //     this.isUsuarioLoading = false
        // });
    }

    Carregar(){
        
    }
    
    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    if(res.data.id)
                        this.$emit("salvou", res.data.id);
                    else
                        this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
}
