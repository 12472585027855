import { Certificado, ProdutoCliente } from ".";

export class CertificadoItem implements Shared.IEntity {

    id: number = 0;
    certificadoId: number = 0;
    certificado!: Certificado;

    fK_ClienteId: number = 0;
    fK_ProdutoId: number = 0;
    produtoCodigo: string = "";

    produtoCliente!: ProdutoCliente;

    constructor(model?: CertificadoItem) {

        if (!model)
            return;

        this.id = model.id;
        this.certificadoId = model.certificadoId;
        this.certificado = model.certificado;

        this.fK_ClienteId = model.fK_ClienteId;
        this.fK_ProdutoId = model.fK_ProdutoId;
        this.produtoCodigo = model.produtoCodigo;

        this.produtoCliente = model.produtoCliente;
    }
}