
import { PageBase } from '@/core/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class MasterPage extends PageBase{
    @Prop() src!: string;
    @Prop() path!: string;    
    @Prop() nome!: string;
    @Prop({default: true}) avatar!: boolean
    @Prop({default: false}) visualizar!: boolean;    

    showImg: boolean = false;
    iniciais: string = "";
    file: any = null;    

    created() {
        this.GetIniciais();
        this.Carregar();
    }

    @Watch("nome")
    @Watch("path")
    Carregar(){
        this.loading = true;
        if (this.path && this.src){
            this.file = `${this.path}/${this.src}`;
        }
        else if (this.path == null && this.src){
            this.file = `data:image/jpeg;base64,${this.src}`;
        }
        else {
            this.file = null;
        }
        this.loading = false;        
    }

    AbrirImagem(){
        if(this.visualizar && this.src){
            this.showImg = true;
        }
    }

    GetIniciais(){
        var rawTeste = this.nome.split(" ");
        this.iniciais += rawTeste[0].trim().charAt(0);
        this.iniciais += rawTeste[rawTeste.length - 1].trim().charAt(0);
    }
}
