import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class PedidoService extends Service {
    
  constructor() {
    super('v1/pedido');
  }

  private MontaFiltro(parametros: string, filter: any){
    if(filter){
      let paramTemp = '';

    if(filter.numeroInicio){
      if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero ge (${filter.numeroInicio})`;
    }

    if(filter.numeroFim){
      if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero le (${filter.numeroFim})`;
    }

    if(filter.pessoaId){
      if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `agendamento/clienteId eq (${filter.pessoaId})`;
    }

    if(filter.importadorId){
      if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `importadorId eq (${filter.importadorId})`;
    }

    if(filter.distribuidorId){
      if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `distribuidorId eq (${filter.distribuidorId})`;
    }

    if(filter.tipoAndamentoId){
      if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `tipoAndamentoId eq (${filter.tipoAndamentoId})`;
    }

    if(filter.dataInicio){
      if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data ge (${filter.dataInicio})`;
    }

    if(filter.dataFim){
      if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data le (${filter.dataFim})`;
    }

    if (paramTemp) {

      if (parametros) {
        parametros += '&';
      }
      else {
        parametros += '?';
      }
        parametros += `$filter=${paramTemp}`;
      }
    }
    return parametros;
  }
    
  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.MontaFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  public CancelarItem(id: number, itemId:number, justificativa: string){
    return http.post(`${this.GetNomeControle()}/${id}/CancelarItem/${itemId}`, undefined, {
      params: {
        justificativa
      }
    });
  }

  public ReabrirItem(id: number, itemId:number){
    return http.post(`${this.GetNomeControle()}/${id}/ReabrirItem/${itemId}`);
  }

  public ProdutoCliente(produtoId: number, clienteId: number){
    return http.get(`${this.GetNomeControle()}/Produto/${produtoId}/Cliente/${clienteId}`);
  }

  public ConfirmacaoCliente(id: number){
    return http.post(`${this.GetNomeControle()}/${id}/ConfirmacaoCliente`);
  }

  //IMPORTAR
  public Importar(id: number, file: any, layout: number){
    let bodyFormData = new FormData();
    bodyFormData.append('arquivo', new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), file.name);

    return http.post(`${this.GetNomeControle()}/${id}/Importar/${layout}`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    });
  }

  //EXPORTAR
  public Exportar(id: number, layout: number){    
    return http.get(`${this.GetNomeControle()}/${id}/Exportar/${layout}`,
    {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/octet-stream',
        'Access-Control-Allow-Origin': '*'
      }
    });
  }

  //GERARPPTX
  public GerarPPTX(id: number){    
    return http.get(`${this.GetNomeControle()}/${id}/GerarPPTX`,
    {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/octet-stream',
        'Access-Control-Allow-Origin': '*'
      }
    });
  }

  // CRONOGRAMA
  public ListarItensCronograma(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/Itens/Cronograma`);
  }
  public SetarItemCronogramaEtapa(pedidoItemId: number, realizado: boolean, etapaId: number){
    return http.put(`${this.GetNomeControle()}/Item/${pedidoItemId}/SetarEtapa`, undefined, {
      params: {
        realizado,
        etapaId
      }
    });
  }

  //RELATORIOS
  public VisualizarEmbarques(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/RelatorioEmbarques`, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/octet-stream',
        'Access-Control-Allow-Origin': '*'
      }
    });
  }
}