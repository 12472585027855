import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class OrgaoCertificadorService extends Service {
    
  constructor() {
    super('v1/orgaocertificador');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q,
      },
      headers: httpHeader.headers
    });
  }
    
}