import { Pedido, Pessoa, Produto, ProdutoCliente, TipoSelecaoPedidoItem } from ".";
import { Operacao } from "../shared";

export class PedidoItem implements Shared.IEntity {

    id: number = 0;
    clienteId: number = 0;
    cliente!: Pessoa;
    pedidoId: number = 0;
    pedido!: Pedido;
    produtoId: number = 0;
    produto!: Produto;
    tipoSelecaoId: number = 0;
    tipoSelecao!: TipoSelecaoPedidoItem;
    ultimoPedidoId?: number;
    ultimoPedido!: PedidoItem;
    quantidade: number = 0;
    valor: number = 0;
    quantidadeInformada: number = 0;
    cancelado: boolean = false;
    cancelamento?: Operacao;
    // cronograma: Cronograma = new Cronograma();
    observacao: string = "";

    produtoCodigo?: string;
    produtoCliente!: ProdutoCliente;    

    constructor(model?: PedidoItem){

        if(!model)
            return;

        this.id = model.id;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.pedidoId = model.pedidoId;
        this.pedido = model.pedido;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
        this.tipoSelecaoId = model.tipoSelecaoId;
        this.tipoSelecao = model.tipoSelecao;
        this.ultimoPedidoId = model.ultimoPedidoId;
        this.ultimoPedido = model.ultimoPedido;
        this.quantidade = model.quantidade;
        this.valor = model.valor;
        this.quantidadeInformada = model.quantidadeInformada;
        this.cancelado = model.cancelado;
        this.cancelamento = model.cancelamento;
        // this.cronograma = model.cronograma;
        this.observacao = model.observacao;

        this.produtoCodigo = model.produtoCodigo;
    }
}

export class Cronograma {

    marketing?: Operacao;
    rotulagem?: Operacao;
    certificacao?: Operacao;
    fabricacao?: Operacao;
    inspecao?: Operacao;
    embarque?: Operacao;    
    prontidao?: Operacao;

    constructor(model?: Cronograma){

        if(!model)
            return;

        this.marketing = model.marketing;
        this.rotulagem = model.rotulagem;
        this.certificacao = model.certificacao;
        this.fabricacao = model.fabricacao;
        this.inspecao = model.inspecao;
        this.embarque = model.embarque;
        this.prontidao = model.prontidao;
    }
}