import { Agendamento, Container, Embarque, PedidoItem, Pessoa, ProformaInvoice, TipoAndamentoPedido } from ".";

export class Pedido implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    identificacao: string = "";
    numero: number = 0;
    data: string = new Date().toYYYYMMDD();    
    agendamentoId: number = 0;
    agendamento!: Agendamento;
    importadorId?: number;
    importador!: Pessoa;
    distribuidorId?: number;
    distribuidor!: Pessoa;
    tipoAndamentoId: number = 1;
    tipoAndamento!: TipoAndamentoPedido;
    containerId: number = 0;
    container!: Container;

    itens: PedidoItem[] = [];
    proformaInvoices: ProformaInvoice[] = [];
    embarques: Embarque[] = [];

    constructor(model?: Pedido){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.identificacao = model.identificacao;
        this.numero = model.numero;
        this.data = model.data.toDateYYYYMMDD();        
        this.agendamentoId = model.agendamentoId;
        this.agendamento = model.agendamento;
        this.importadorId = model.importadorId;
        this.importador = model.importador;
        this.distribuidorId = model.distribuidorId;
        this.distribuidor = model.distribuidor;
        this.tipoAndamentoId = model.tipoAndamentoId;
        this.tipoAndamento = model.tipoAndamento;
        this.containerId = model.containerId;
        this.container = model.container;
        
        this.itens = model.itens;
        this.proformaInvoices = model.proformaInvoices;
        this.embarques = model.embarques;
    }
}