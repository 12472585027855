import { Container, EmbarqueItem, Pedido, Pessoa, Porto, TermoPagamento } from ".";

export class Embarque implements Shared.IEntity{

    id: number = 0;
    pedidoId: number = 0;
    pedido!: Pedido;
    desmembramentoId?: number;
    desmembramento!: Embarque;
    evento: string = "";
    desmembrado: boolean = false;
    containerId: number = 0;
    container!: Container;
    data?: string;
    exportadorId?: number;
    exportador!: Pessoa;
    clienteId?: number;
    cliente!: Pessoa;
    portoCargaId?: number;
    portoCarga!: Porto
    portoDescargaId?: number;
    portoDescarga!: Porto;
    po: string = "";
    invoice: string = "";
    bl: string = "";
    dataLancamentoBL?: string;
    // termoPagamentoId?: number;
    // termoPagamento!: TermoPagamento;
    termoPagamento: string = "";

    itens: EmbarqueItem[] = [];    

    constructor(model?: Embarque){

        if(!model)
            return;

        this.id = model.id;
        this.pedidoId = model.pedidoId;
        this.pedido = model.pedido;
        this.desmembramentoId = model.desmembramentoId;
        this.desmembramento = model.desmembramento;
        this.evento = model.evento;
        this.desmembrado = model.desmembrado;
        this.containerId = model.containerId;
        this.container = model.container;
        this.data = model.data?.toDateYYYYMMDD();
        this.exportadorId = model.exportadorId;
        this.exportador = model.exportador;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.portoCargaId = model.portoCargaId;
        this.portoCarga = model.portoCarga;
        this.portoDescargaId = model.portoDescargaId;
        this.portoDescarga = model.portoDescarga;
        this.po = model.po;
        this.invoice = model.invoice;
        this.bl = model.bl;
        this.dataLancamentoBL = model.dataLancamentoBL ? model.dataLancamentoBL.toDateYYYYMMDD() : "";
        // this.termoPagamentoId = model.termoPagamentoId;
        this.termoPagamento = model.termoPagamento;

        this.itens = model.itens;
    }
}