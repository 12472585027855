
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { Produto, ProdutoCliente, ProdutoClienteFoto, TipoEmbalagem, UnidadeMedida } from '@/core/models/geral';
import { MOQService, PessoaService, ProdutoService, TipoEmbalagemService, UnidadeMedidaService } from '@/core/services/geral';
import { AlertSimple, AlertSimpleErr, AlertSimpleRes, ArquivoService } from '@/core/services/shared';

@Component
export default class CadastroClienteProduto extends CrudBase {
    @Prop() private value!: string;
    @Prop() item!: ProdutoCliente;
    @Prop({default: false}) editar!: boolean;

    gedFoto: any = this.GEDProduto();
    gedFotoProdutoPessoa: any = "";

    dialog: boolean = false;
    $refs!: {
        form: HTMLFormElement,
        formFoto: HTMLFormElement
    }

    editaCodigo: boolean = false;

    validFoto: boolean = true;

    moqService: MOQService = new MOQService();
    
    produtos: Produto[] = [];
    produtoService: ProdutoService = new ProdutoService();
    onSearchProduto: any = null;
    isProdutoLoading: boolean = false;

    tipoEmbalagens: TipoEmbalagem[] = [];
    tipoEmbalagemService: TipoEmbalagemService = new TipoEmbalagemService();

    unidadeMedidas: UnidadeMedida[] = [];
    unidadeMedidaService: UnidadeMedidaService = new UnidadeMedidaService();

    clienteService: PessoaService = new PessoaService();
    fotos: ProdutoClienteFoto[] = [];
    arquivo: any = null;
    fotoHeaders: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Capa', value:'capa' },
        { text: 'Foto', value:'foto', sortable: false },
        { text: 'Nome', value:'nome' }
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.gedFotoProdutoPessoa = this.GEDProdutoCliente(this.item.pessoaId, this.item.produtoId, this.item.codigo);
            this.Carregar();
            if (this.$refs.form)
                this.$refs.form.resetValidation();
            }
        else{
            this.$emit("fechou");
        }
    }

    @Watch("item")
    WatchItem(){
        if(this.item.produto)
            this.produtos.push(this.item.produto);        
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    @Watch('onSearchProduto')
    searchProduto (val: string) {
        if (this.item.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isProdutoLoading = false
        });
    }

    AtribuiPadraoProduto(){
        if(this.item.produtoId > 0){
            this.item.produto = this.produtos.find(x => x.id == this.item.produtoId)!;
            this.item.cxInner = this.item.produto.cxInner;
            this.item.cxMaster = this.item.produto.cxMaster;
            this.item.tipoEmbalagemId = this.item.produto.tipoEmbalagemId;
            this.item.cubagemUnitaria = this.item.produto.cubagem.cubagem;            
        }
    }

    mounted() {
        
    }

    Carregar(){
        this.tipoEmbalagemService.ListarTudo().then(
            res => this.tipoEmbalagens = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.unidadeMedidaService.ListarTudo().then(
            res => this.unidadeMedidas = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        if(this.editar)
            this.ListarFotos();
    }

    ListarFotos(){
        this.clienteService.ListarProdutoClienteFotos(this.item.pessoaId, this.item.produtoId, this.item.codigo).then(
            res => {
                this.fotos = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    async AdicionarFoto(){
        let arquivoGravar = await new ArquivoService().LerArrayBuffer(this.arquivo);
        this.clienteService.GravarProdutoClienteFoto(this.item.pessoaId, this.item.produtoId, this.arquivo.type, this.arquivo.name, this.item.codigo, arquivoGravar).then(
            res => {
                AlertSimpleRes("Aviso!", res)
                this.ListarFotos();
                this.arquivo = null;
                arquivoGravar = "";
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }    

    DownloadFoto(pessoaId: number, produtoId: number, codigo: string, nome: string){
        this.clienteService.LerProdutoClienteFotos(pessoaId, produtoId, nome, codigo).then(
            res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', nome);
                document.body.appendChild(link);
                link.click();
            },
            err => AlertSimpleErr("Aviso!", err)
        );;
    }

    ExcluirFoto(pessoaId: number, produtoId: number, codigo: string, fotoId: number, nome: string){
        this.clienteService.DeletarProdutoClienteFoto(pessoaId, produtoId, codigo, fotoId, nome).then(
            res => {
                AlertSimpleRes("Aviso!", res);
                this.ListarFotos();
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    TornarCapa(pessoaId: number, produtoId: number, nome: string, codigo: string){
        this.clienteService.TornarCapa(pessoaId, produtoId, nome, codigo).then(
            res => {
                AlertSimpleRes("Aviso!", res);
                this.ListarFotos();
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()){
            this.item.produto = this.produtos.find(x => x.id == this.item.produtoId)!;            
            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.$emit("fechou");
        this.dialog = false;
    }
}
