import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared/Service";
import { AlertSimpleErr } from "../shared";

export class PessoaService extends Service {
    
    constructor() {
        super('v1/pessoa');
    }

    public AutoComplete(q: string, tipo: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q,
                tipo
            },
            headers: httpHeader.headers
        });
    }

    public VincularAgrupador(id: number, agrupadorId?: number){
        return http.post(`${this.GetNomeControle()}/${id}/VincularAgrupador/${agrupadorId}`);
    }

    public ListarGrupos(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/ListarGrupos`);
    }

    public GetProdutos(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/GetProdutos`);
    }

    public AtualizarDadosClienteProduto(id: number, produtoId: number, produtoCodigo: string, nome: string, codigo: string, cbmUnitaria: number, cxInner: number, cxMaster: number, ean: string, eanInner: string, eanMaster: string, ncm: string){
        return http.put(`${this.GetNomeControle()}/${id}/Produto/${produtoId}/AtualizarDados`, undefined , {
            params: {
                produtoCodigo,
                nome,
                codigo,
                cbmUnitaria,
                cxInner,
                cxMaster,
                ean,
                eanInner,
                eanMaster,
                ncm
            },
            headers: httpHeader.headers
        });
    }

    //GED
    ListarProdutoClienteFotos(pessoaId: number, produtoId: number, codigo: string){
        return http.get(`${this.GetNomeControle()}/${pessoaId}/ProdutoFoto/${produtoId}/${codigo}`);
    }
    LerProdutoClienteFotos(pessoaId: number, produtoId: number, nome: string, codigo: string){
        return http.get(`${this.GetNomeControle()}/${pessoaId}/ProdutoFoto/${produtoId}/${codigo}/Download`, {
            responseType: 'blob',
                params: {
                    nome
                }
            }
        );
    }
    GravarProdutoClienteFoto(pessoaId: number, produtoId: number, tipo: string, nome: string, codigo: string, arquivo: any){
        let bodyFormData = new FormData();
        var blob = new Blob([arquivo], { type: tipo });
        bodyFormData.append('arquivo', blob, nome);
        return http.post(`${this.GetNomeControle()}/${pessoaId}/ProdutoFoto/${produtoId}/${codigo}`, bodyFormData, {
            headers: {'Content-Type': 'multipart/form-data' }
        });
    }
    DeletarProdutoClienteFoto(pessoaId: number, produtoId: number, codigo: string, produtoClienteFotoId: number, nome: string){
        return http.delete(`${this.GetNomeControle()}/${pessoaId}/ProdutoFoto/${produtoId}/${codigo}/Excluir/${produtoClienteFotoId}`, {
            params: {
                nome
            }
        });
    }
    TornarCapa(pessoaId: number, produtoId: number, nome: string, codigo: string){
        return http.post(`${this.GetNomeControle()}/${pessoaId}/ProdutoFoto/${produtoId}/${codigo}/TornarCapa`, undefined, { 
            params: {
                nome
            }
        });
    }    

    public Importar(id: number, file: any){
        let bodyFormData = new FormData();
        bodyFormData.append('arquivo', new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), file.name);

        return http.post(`${this.GetNomeControle()}/${id}/Importacao/Produto`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }
    public ImportarFotos(id: number, file: any){
        let bodyFormData = new FormData();
        bodyFormData.append('arquivo', new Blob([file], { type: 'application/zip' }), file.name);

        return http.post(`${this.GetNomeControle()}/${id}/Importacao/ProdutoFotos`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }
}