import { Pessoa } from "./Pessoa";

export class Agendamento implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    numero: number = 0;
    dataHora!: string;
    clienteId: number = 0;
    cliente?: Pessoa;    
    porcentagem: number = 0;

    constructor(model?: Agendamento){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.dataHora = model.dataHora;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;        
        this.porcentagem = model.porcentagem;
    }
}