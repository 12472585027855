
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { Agendamento, PedidoItem, Produto } from '@/core/models/geral';
import { MOQService, PedidoService, ProdutoService } from '@/core/services/geral';
import { AlertSimple, AlertSimpleErr } from '@/core/services/shared';

@Component
export default class CadastroPedidoItem extends CrudBase {
    @Prop() private value!: string;
    @Prop() item!: PedidoItem;
    @Prop() agendamento!: Agendamento;
    @Prop() pedidoItens!: PedidoItem[];
    @Prop({default: 0}) fatorCusto!: number;
    @Prop({default: 0}) fatorVenda!: number;

    gedFoto: any = this.GEDProduto();

    dialog: boolean = false;
    $refs!: {
        form: HTMLFormElement        
    }

    moqService: MOQService = new MOQService();
    produtoService: ProdutoService = new ProdutoService();
    produtos: Produto[] = [];
    novoItem: boolean = true;
    produtosCliente: any[] = [];
    onSearchProduto: any = null;
    isProdutoLoading: boolean = false;

    leitorCodigoBarra: boolean = false;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            if (this.$refs.form)
                this.$refs.form.resetValidation();
            if(this.item.id > 0)
                this.produtos.push(this.item.produto);            
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    itemClass(item: any){
        if(!item.ativo)
            return 'cancelado'
    }

    @Watch('onSearchProduto')
    searchProduto (val: string) {
        if (this.item.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        //COLOCADO PARA OTIMIZAR UM POUCO A PESQUISA POR CONTA DA QUANTIDADE DE ITENS QUE EXISTEM CADASTRADOS
        if(val.length < 4) return;
        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isProdutoLoading = false
        });
    }

    @Watch("item.produtoId")
    WatchProduto(){
        if(this.item.produtoId > 0){
            new PedidoService().ProdutoCliente(this.item.produtoId, this.item.clienteId).then(
                res => {
                    this.produtosCliente = res.data;                    
                    //QUANDO TEM APENAS UM JA TRAZ TUDO PREENCHIDO
                    if(this.item.id == 0){
                        if(this.produtosCliente.length > 0){
                            let produtoCliente = this.produtosCliente[0];
                            this.item.produto = this.produtos.find(x => x.id == this.item.produtoId)!;
                            this.item.produtoCodigo = produtoCliente.codigo;
                            if(produtoCliente.ultimoPedidoId){
                                this.item.ultimoPedidoId = produtoCliente.ultimoPedidoId;
                                this.item.valor = produtoCliente.ultimoFobPedido;
                            }
                            else{
                                this.item.valor = Math.round((this.item.produto.fob + (this.item.produto.fob * this.agendamento.porcentagem / 100)) * 100) / 100;
                            }
                            if(this.item.produto.moq > 0){
                                this.item.quantidade = this.item.produto.moq;
                            }
                            else{
                                this.CalcularMOQ();
                            }
                        }
                        else{
                            this.NovoProduto();
                        }
                    }

                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    NovoProduto(){
        this.item.produtoCodigo = undefined;
        this.item.ultimoPedidoId = undefined;
        this.item.produto = this.produtos.find(x => x.id == this.item.produtoId)!;
        this.item.valor = Math.round((this.item.produto.fob + (this.item.produto.fob * this.agendamento.porcentagem / 100)) * 100) / 100;
        if(this.item.produto.moq > 0){
            this.item.quantidade = this.item.produto.moq;
        }
        else{
            this.CalcularMOQ();
        }
    }

    CalcularMOQ(){
        this.moqService.CalcularMOQ(this.item.valor, this.agendamento.id).then(
            res => {
                let retorno = res.data;
                this.item.quantidade = retorno.quantidade;                
            },
            err => AlertSimpleErr("Aviso!", err)            
        )
    }

    mounted() {
        
    }

    Carregar(){
        
    }

    AbrirLeitorCodigoBarra(){
        this.leitorCodigoBarra = true;
    }

    LeitorDecode(value: any){
        this.leitorCodigoBarra = false;
        if(value){
            this.onSearchProduto = value;
            this.produtoService.AutoComplete(value).then(
                res => {
                    let produtos = res.data;
                    if(produtos.length > 0)
                        this.item.produtoId = produtos[0].id;
                    else
                        AlertSimple("Aviso!", "Produto não encontrado!", "warning")
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }

    }    

    Salvar(){
        if (this.$refs.form.validate()){
            if(!this.item.produto.ativo){
                AlertSimple("Aviso!", "O produto que está tentando vincular não está ativo.", "warning");
            }
            else if(this.produtosCliente.length > 0 && this.item.produtoCodigo && !this.produtosCliente.find(x => x.codigo == this.item.produtoCodigo).ativo){
                AlertSimple("Aviso!", "O produto do cliente que está tentando vincular não está ativo.", "warning");
            }
            else{
                if(this.item.id == 0 && this.pedidoItens.find(x => x.produtoId == this.item.produtoId && x.produtoCodigo && x.produtoCodigo == this.item.produtoCodigo && x.observacao == this.item.observacao)){
                    AlertSimple("Aviso!", "Não é possível adicionar um produto já vinculado ao pedido.", "warning");
                }
                else if(this.item.id == 0 && this.pedidoItens.find(x => x.produtoId == this.item.produtoId && !x.produtoCodigo && !this.item.produtoCodigo && x.observacao == this.item.observacao)){
                    AlertSimple("Aviso!", "O novo produto que está tentando vincular já se encontra no pedido. Adicione uma observação diferente para poder prosseguir.", "warning");
                }else{
                    this.Salvou();
                }
            }
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.$emit("fechou");
        this.dialog = false;
    }
}
