
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertQuestion, AlertSimpleErr, AlertSimpleNotification, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Container, Embarque, EmbarqueItem, Pedido, PedidoItem, Pessoa, Porto, TermoPagamento } from '@/core/models/geral';
import { CategoriaProdutoService, ContainerService, PedidoService, PessoaService, PortoService, TermoPagamentoService } from '@/core/services/geral';
import { EmbarqueService } from '@/core/services/geral/EmbarqueService';

@Component
export default class CadastroEmbarque extends CrudBase{
    @Prop() id!: number;
    @Prop() pedidoId!: number;
    @Prop() desmembrarId!: number;
    @Prop() clonarId!: number;
    @Prop() private value!: string;    

    gedFoto: any = this.GEDProduto();

    item: Embarque = new Embarque();
    itemOriginal!: Embarque;
    service: EmbarqueService = new EmbarqueService();
    $refs!: {
        form: HTMLFormElement
    }
    
    portos: Porto[] = [];
    portoService: PortoService = new PortoService();    

    termoPagamentos: TermoPagamento[] = [];
    termoPagamentoService: TermoPagamentoService = new TermoPagamentoService();

    itensHeader: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Cod. China', value: 'item.produto.codigoChina' },
        { text: 'Foto', value: 'item.produto.foto', sortable: false },
        { text: 'Cod. China', value: 'item.produtoCliente.codigo' },
        { text: 'CX Master', value: 'item.produtoCliente.cxMaster' },
        { text: 'CX Inner', value: 'item.produtoCliente.cxInner' },
        { text: 'Fabricante', value: 'fabricanteId' },
        { text: 'Ajuste Container', value: 'ajusteContainer' },
        { text: 'Pedido Original', value: 'pedidoOriginal' },
        { text: 'Total Pedido', value: 'totalItem' },
        { text: 'Quantidade', value: 'quantidade' },        
        { text: 'Carton', value: 'carton' },
        { text: 'CBG', value: 'cbg' }
    ]

    fabricanteId: number = 0;
    fabricantes: Pessoa[] = [];
    fabricanteService: PessoaService = new PessoaService();
    isFabricanteLoading: boolean = false;

    pessoaService: PessoaService = new PessoaService();    
    exportadores: Pessoa[] = [];
    onSearchExportador: any = null;
    isExportadorLoading: boolean = false;

    loadingItem: boolean = false;

    dialogCadastroItem: boolean = false;
    itensSelecionados: PedidoItem[] = [];

    pedidoService: PedidoService = new PedidoService();
    agrupador:any = null;

    containers: Container[] = [];
    containerService: ContainerService = new ContainerService();

    embarqueItens: EmbarqueItem[] = [];

    //DataBL
    dataLancamentoBL: string = "";

    //FILTROS
    panel: any = [];
    categorias: any[] = [];
    categoriaService: CategoriaProdutoService = new CategoriaProdutoService();

    filtro: any = {
        categoriaId: null
    }

    myInterval: any = null;

    metricas: any = {
        cbmContainerTotal: 0,
        cbmTotal: 0,
        valorTotal: 0
    }

    itemClass(item: any){
        if(item.item.cancelado)
            return 'cancelado'
    }

    @Watch('id')
    WatchId(){
        if(this.id > 0){
            this.loading = true;
            this.service.ObterPorId(this.id, "Container, Exportador, Pedido.Container, Itens.Item.Produto, Itens.Item.ProdutoCliente").then(
                res => {
                    this.item = new Embarque(res.data);
                    this.dataLancamentoBL = this.item.dataLancamentoBL ? this.item.dataLancamentoBL : "";
                    this.CalcularMetricaItem();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
        else{
            this.item = new Embarque();
            this.item.pedidoId = this.pedidoId;
        }
    }

    @Watch("desmembrarId")
    WatchDesmembrar(){
        if(this.desmembrarId){
            this.loading = true;
            this.service.ObterPorId(this.desmembrarId, "Container, Exportador, Itens.Item.Produto, Itens.Item.ProdutoCliente").then(
                res => {
                    let embarqueRaw = new Embarque(res.data);
                    this.item = new Embarque();
                    this.item.pedidoId = embarqueRaw.pedidoId;
                    this.item.desmembramentoId = this.desmembrarId;
                    this.item.containerId = embarqueRaw.containerId;
                    this.item.data = embarqueRaw.data;
                    this.item.exportadorId = embarqueRaw.exportadorId;
                    this.item.exportador = embarqueRaw.exportador;
                    this.item.portoCargaId = embarqueRaw.portoCargaId;
                    this.item.portoDescargaId = embarqueRaw.portoDescargaId;
                    this.item.invoice = embarqueRaw.invoice;
                    this.item.bl = embarqueRaw.bl;
                    this.item.dataLancamentoBL = embarqueRaw.dataLancamentoBL;
                    this.item.po = embarqueRaw.po;
                    // this.item.termoPagamentoId = embarqueRaw.termoPagamentoId;
                    this.item.termoPagamento = embarqueRaw.termoPagamento;
                    embarqueRaw.itens.forEach((item, index) => {
                        if(!item.item.cancelado){
                            let embarqueItem = new EmbarqueItem();
                            embarqueItem.embarqueId = item.embarqueId;
                            embarqueItem.itemId = item.itemId;
                            embarqueItem.item = item.item;
                            embarqueItem.fabricanteId = item.fabricanteId;
                            embarqueItem.quantidadeEmbarqueAntigo = item.quantidade;
                            this.item.itens.push(embarqueItem);
                        }
                        if(embarqueRaw.itens.length == index + 1){
                            this.FiltrarItens();
                        }
                    });
                },
                err => AlertSimpleErr("Aviso!", err) 
            ).finally(() => {
                this.loading = false;                
            })
        }
    }

    @Watch("clonarId")
    WatchClonar(){
        this.service.ObterPorId(this.clonarId, "Container, Itens.Item.Produto, Itens.Item.ProdutoCliente").then(
            res => {
                let embarqueRaw = new Embarque(res.data);
                this.item = new Embarque();
                this.item.pedidoId = embarqueRaw.pedidoId;
                this.item.containerId = embarqueRaw.containerId;
                embarqueRaw.itens.forEach((item, index) => {
                    if(!item.item.cancelado){
                        let embarqueItem = new EmbarqueItem();
                        embarqueItem.embarqueId = item.embarqueId;
                        embarqueItem.itemId = item.itemId;
                        embarqueItem.item = item.item;
                        embarqueItem.fabricanteId = item.fabricanteId;
                        embarqueItem.quantidade = item.item['saldo'] ?? 0;
                        this.item.itens.push(embarqueItem);
                    }
                    if(embarqueRaw.itens.length == index + 1){
                        this.FiltrarItens();
                    }
                });
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
            if(this.item.dataLancamentoBL == null){
                this.myInterval = setInterval(this.SalvarAutomatico, 15000);
            }
        }
        else {
            this.$emit("fechou");
        }
    }    

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.fabricanteId = this.FabricantePredominante()!;

            if(this.item.exportadorId)
                this.exportadores.push(this.item.exportador);

            if(this.item.desmembramentoId){
                this.service.ObterPorId(this.item.desmembramentoId, "Container, Itens.Item.Produto, Itens.Item.ProdutoCliente").then(
                    res => {
                        let embarqueRaw = new Embarque(res.data);
                        embarqueRaw.itens.forEach(item => {
                            this.item.itens.find(x => x.itemId == item.itemId)!.quantidadeEmbarqueAntigo = item.quantidade;                                                    
                        });
                    },
                    err => AlertSimpleErr("Aviso!", err)
                )
            }

            this.CarregarCategorias();
            this.FiltrarItens();            
        }
        if(this.item.pedidoId > 0)
            this.VerificarClienteAgrupador();
        if(this.item.exportadorId)
            this.exportadores.push(this.item.exportador);
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('onSearchExportador')
    searchCliente (val: string) {
        if (this.item.exportadorId) return;
        if (this.isExportadorLoading) return;
        if (!val) return;
        this.isExportadorLoading = true;
        this.pessoaService.AutoComplete(val, "e").then(
            res => {
                this.exportadores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isExportadorLoading = false
        });
    }

    @Watch("item.portoDescargaId")
    WatchPortoDesembarque(){
        if(this.item.portoDescargaId && this.desmembrarId > 0){
            let porto = this.portos.find(x => x.id == this.item.portoDescargaId)!;
            this.item.invoice += porto.estado;
        }
    }

    VerificacaoQuantidade(item: any){
        return !this.item.desmembrado && item.quantidadeEmbarqueAntigo > 0 ? [...[item.quantidade <= item.quantidadeEmbarqueAntigo || `Valor não pode ser maior que ${item.quantidadeEmbarqueAntigo}`], ...[item.quantidade % item.item.produtoCliente.cxMaster === 0 || 'Quantidade informada não é um múltiplo de CX.Master']] : [...[item.quantidade % item.item.produtoCliente.cxMaster === 0 || 'Quantidade informada não é um múltiplo de CX.Master']];
    }

    ToUpper(val: string){
        this.item.evento = val.toUpperCase().replace(/[ÀÁÂÃÄÅ]/g,"A").replace(/[àáâãäå]/g,"a").replace(/[ÈÉÊË]/g,"E").replace(/[ç]/g,"c").replace(/[Ç]/g,"C");
    }
    
    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    WatchFabricante(){
        if(this.fabricanteId > 0){
            this.item.itens.forEach(item => {
                if(!item.item.cancelado)
                    item.fabricanteId = this.fabricanteId;
            });
        }
    }

    FiltrarItens(){
        if(this.filtro.categoriaId)
            this.embarqueItens = this.item.itens.filter(x => x.item.produto.categoriaId == this.filtro.categoriaId);
        else
            this.embarqueItens = this.item.itens;
    }

    VerificarClienteAgrupador(){
        this.pedidoService.ObterPorId(this.item.pedidoId, "Agendamento.Cliente.Grupos").then(
            res => {
                let pedido = new Pedido(res.data);
                if(pedido.agendamento.cliente!.isGrupo){
                    this.agrupador = new Pessoa(pedido.agendamento.cliente);
                }
                else {
                    this.agrupador = null;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    CalcularMetricaItem(){
        let itensModel = this.item.itens.map(x => ({
            Id: x.id,
            ProdutoId: x.item.produtoId,
            PedidoItemId: x.itemId,
            ProdutoCodigo: x.item.produtoCodigo,
            Observacao: x.item.observacao,
            Quantidade: x.quantidade
        }));
        this.loadingItem = true;
        this.service.ItemMetricas(this.item.id, this.item.pedidoId, this.item.desmembrado ? false : this.item.desmembramentoId ? true : false, itensModel).then(
            res => {
                let retorno = res.data;
                retorno.forEach((elemento, index) => {
                    let embarqueItem = this.item.itens.find(x => x.id == elemento.id);
                    embarqueItem!.carton = elemento.carton;
                    embarqueItem!.cbmCtnCxMaster = elemento.cbmCtnCxMaster;
                    embarqueItem!.cbg = elemento.cbg;
                    embarqueItem!.valor = elemento.valor;
                    embarqueItem!.pedidoOriginal = elemento.pedidoOriginal;
                    embarqueItem!.totalItem = elemento.totalItem;
                    embarqueItem!.ajusteContainer = elemento.ajusteContainer;
                    
                    if(index == retorno.length - 1)
                        this.CalcularMetricasEmbarque();
                });
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loadingItem = false;
        })
    }

    CalcularMetricasEmbarque(){
        this.metricas.cbmContainerTotal = this.item.container ? this.item.itens.reduce((a, b) => a + b['cbg'], 0) / this.item.pedido.container.cubagem : 0;
        this.metricas.cbmTotal = this.item.itens.reduce((a, b) => a + b['cbg'], 0);
        this.metricas.valorTotal = this.item.itens.reduce((a, b) => a + b['valor'], 0);
    }

    FabricantePredominante(){
        let count = {};
        let maxCount = 0;
        let maxKey = null;
        for (let key of this.item.itens) {
            if (count[`${key.fabricanteId}`]) {
                count[`${key.fabricanteId}`]++;
            } 
            else {
                count[`${key.fabricanteId}`] = 1;
            }
            if (count[`${key.fabricanteId}`] > maxCount) {
                maxCount = count[`${key.fabricanteId}`];
                (maxKey as any) = key.fabricanteId;
            }
        }
        return maxKey;
    }

    Carregar(){
        this.portoService.ListarTudo().then(
            res => {
                this.portos = res.data.items
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.fabricanteService.ListarTudoFiltro("isFabricante eq true").then(
            res => {
                this.fabricantes = res.data.items
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.containerService.ListarTudo().then(
            res => {
                this.containers = res.data.items
            },
            err => AlertSimpleErr("Aviso!", err)
        )     
        // this.termoPagamentoService.ListarTudo().then(
        //     res => {
        //         this.termoPagamentos = res.data.items
        //     },
        //     err => AlertSimpleErr("Aviso!", err)
        // )
    }

    CarregarCategorias(){
        var categoriaIds = "";
        let itens = this.item.itens.map(x => x.item.produto.categoriaId);
        itens.forEach((x, index) => {
            if(x){
                categoriaIds += index == itens.length - 1 ? `${x.toString()}` : `${x.toString()},`;
            }
        });
        if(categoriaIds.length > 0){
            this.categoriaService.GetByIds(categoriaIds).then(
                res => {
                    this.categorias = res.data;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    AbrirCadastroItens(){
        if(this.$refs.form.validate()){
            this.dialogCadastroItem = true;
        }
    }

    AdicionarItens(){
        this.itensSelecionados.forEach((x, index) => {
            if(!this.item.itens.find(y => y.itemId == x.id)){
                let embarqueItem = new EmbarqueItem();
                embarqueItem.embarqueId = this.item.id;
                embarqueItem.itemId = x.id;
                embarqueItem.item = x;
                if(x['saldo'])
                    embarqueItem.quantidade = x['saldo'];
                this.item.itens.push(embarqueItem);
            }            
        });
    }

    ExcluirItem(item: EmbarqueItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);                
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    LancarDataBL(){
        let context = this;
        const lancarData = function () {
            return new Promise( async function (resolve, reject){
                context.item.dataLancamentoBL = context.dataLancamentoBL;
                context.Salvar();
            });
        };
        AlertQuestion("Aviso!", "Tem certeza que deseja encerrar o embarque ? A operação não poderá ser desfeita.", lancarData);
    }

    SalvarAutomatico(){
        if (this.$refs.form.validate()) {
            if(!this.item.dataLancamentoBL){
                this.loading = true;
                let patchModel = jiff.diff(this.itemOriginal, this.item, false);
                (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                    res => {
                        this.id = this.item.id > 0 ? this.item.id : res.data.id;
                        AlertSimpleNotification("Salvando...", "success");
                        this.WatchId();
                    },
                    err => AlertSimpleErr("Aviso!", err)
                )
            }
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;            
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    let id = res.data.id ? res.data.id : this.item.id;
                    if(this.item.desmembramentoId && !this.item.desmembrado){
                        this.service.Desmembrar(id, this.item.desmembramentoId).then(
                            res => {
                                AlertSimpleRes("Aviso", res);
                                this.$emit("salvou");
                                this.Close();
                            },
                            err => AlertSimpleErr("Aviso", err)
                        )
                    }
                    else {
                        AlertSimpleRes("Aviso", res);
                        this.$emit("salvou");
                        this.Close();
                    }
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.tabActive = {};
        this.dialog = false;
        this.item = new Embarque();
        this.desmembrarId = 0;
        this.agrupador = undefined;
        this.filtro.categoriaId = null;
        this.embarqueItens = [];
        this.fabricanteId = 0;
        this.dataLancamentoBL = "";
        this.metricas = {
            cbmContainerTotal: 0,
            cbmTotal: 0,
            valorTotal: 0
        }

        clearInterval(this.myInterval);
    }
}
