
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { CategoriaProduto, Funcionamento, Mecanismo, Produto, ProdutoFOB, TipoEmbalagem } from '@/core/models/geral';
import { CategoriaProdutoService, FuncionamentoService, MecanismoService, ProdutoService, TipoEmbalagemService } from '@/core/services/geral';
import { ArquivoService } from '@/core/services/shared';

@Component
export default class CadastroProduto extends CrudBase{
    @Prop() private value!: string;
    @Prop() item!: Produto;

    itemOriginal!: Produto;
    service: ProdutoService = new ProdutoService();
    $refs!: {
        form: HTMLFormElement,
        foto: HTMLFormElement
    }
    panel = [0];

    fotoTela: any = null;
    fotoCarregar: any = null;

    categorias: CategoriaProduto[] = [];
    categoriaService: CategoriaProdutoService = new CategoriaProdutoService();

    tipoEmbalagens: TipoEmbalagem[] = [];
    tipoEmbalagemService: TipoEmbalagemService = new TipoEmbalagemService();

    funcionamentos: Funcionamento[] = [];
    funcionamentoService: FuncionamentoService = new FuncionamentoService();

    mecanismos: Mecanismo[] = [];
    mecanismoService: MecanismoService = new MecanismoService();

    dialogFobs: boolean = false;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    //PARA APARECER NA TELA QUANDO COLOCADO CARREGADO
    async LoadImage(){
        this.fotoCarregar = this.$refs.foto.files[0];
        if (!this.fotoCarregar)
            return;
        const arquivoService = new ArquivoService();
        const dados = await arquivoService.LerDataURL(this.fotoCarregar);
        this.fotoTela = dados.replace(/^[^,]*,/, "");
    }
    RemoveImage(){
        if(this.item.id > 0){
            this.service.ExcluirFoto(this.item.id).then(
                res => {
                    AlertSimpleRes("Aviso!", res);
                    this.fotoCarregar = null;
                    this.fotoTela = null;
                    this.item.foto = "";
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else {
            this.fotoCarregar = null;
            this.fotoTela = null;
            this.item.foto = "";
        }
    }

    Carregar(){
        this.categoriaService.Listagem().then(
            res => {
                this.categorias = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.tipoEmbalagemService.ListarTudo().then(
            res => {
                this.tipoEmbalagens = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.funcionamentoService.ListarTudo().then(
            res => {
                this.funcionamentos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.mecanismoService.ListarTudo().then(
            res => {
                this.mecanismos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        if(this.item.id > 0){
            this.service.CarregarFoto(this.item.id).then(
                res => {
                    this.fotoTela = res.data;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    CalcularCubagem(){
        this.item.cubagem.cubagem = this.item.cubagem.altura * this.item.cubagem.largura * this.item.cubagem.comprimento;
    }

    CalcularCubagemInner(){
        this.item.cubagemInner.cubagem = this.item.cubagemInner.altura * this.item.cubagemInner.largura * this.item.cubagemInner.comprimento;
    }

    CalcularCubagemMaster(){
        this.item.cubagemMaster.cubagem = this.item.cubagemMaster.altura * this.item.cubagemMaster.largura * this.item.cubagemMaster.comprimento;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    var id = this.item.id > 0 ? this.item.id : res.data.id;
                    var nome = this.item.id > 0 ? this.item.codigo : res.data.codigo;
                    if(this.fotoCarregar){
                        this.service.GravarFoto(id, this.fotoCarregar.type, nome + ".jpg", this.fotoCarregar).then(
                            res => {
                                AlertSimpleRes("Aviso", res);
                                this.Salvou();
                            },
                            err => AlertSimpleErr("Aviso!", err)
                        )
                    }
                    else{
                        AlertSimpleRes("Aviso", res);
                        this.Salvou();
                    }
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.fotoCarregar = null;
        this.fotoTela = null;
        this.dialog = false;
    }
}
