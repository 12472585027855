import { Vue } from "vue-property-decorator";
import { JSONToCSVConvertor, TableToExcel } from '@/assets/scripts/helper';

export class PageBase extends Vue {

    public noSpecialRules = [ 
        v => !!v || "Campo é obrigatório", 
        v => !(/[záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+$/.test(v)) || 'Não é permitido caracteres especiais'
    ];
    public emailRules = [v => !!v && /^([a-zA-Z.-_]|[0-9])+@([a-zA-Z-]|[0-9])+(\.[a-zA-Z]{2,3})?(\.[a-zA-Z]{2,3})$/.test(v) || 'E-mail precisa ser válido'];
    public fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    public numberFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v >= 0) || "Campo obrigatório"];
    public diaSemanaRules: any[] = [v => !!v && /^(?:[1-9]|[12]\d|3[01])$/.test(v) || 'Dia bom deve ser entre 1 e 31'];

    public mskCnpj = process.env.VUE_APP_MSK_CNPJ;
    public mskCpf = process.env.VUE_APP_MSK_CPF;
    public mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    public mskCelular = process.env.VUE_APP_MSK_CELULAR;
    public mskCep = process.env.VUE_APP_MSK_CEP;
    
    public loading: boolean = false;

    public sessionApp: any;
    public app: {empresa: any, empresaFoto: string, nome: string, usuarioFoto: string, login: string, temaEscuro: boolean, sistema: any} = {
        empresa: {
            nome: '',
            cpfCnpj: '',
            endereco: '',
            cep: '',
            inscricaoEstadual: '',
            inscricaoMunicipal: '',
            cpfCnpjNome: '',
            municipio: '',
            estado: ''
        },
        empresaFoto: '',
        nome: '',
        usuarioFoto: '',
        login: '',
        temaEscuro: false,
        sistema: {
            autenticacaoUrl: '',
            nome: '',
            url: '',
            modulos: []
        }
    };

    constructor(){
        super();

        if (!localStorage.sessionApp){
            this.RetornaAuth();
            return;
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.empresa = this.sessionApp.dados.empresa;
        this.app.empresaFoto = this.sessionApp.dados.empresaFoto;
        this.app.nome = this.sessionApp.dados.usuario;
        this.app.usuarioFoto = this.sessionApp.dados.usuarioFoto;
        this.app.login = this.sessionApp.dados.usuarioEmail;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.sistema = this.sessionApp.dados.sistema;
    }

    public RetornaAuth(){
        var path = this.sessionApp ? this.app.sistema.autenticacaoUrl : process.env.VUE_APP_AUTHURL;
        localStorage.clear();
        window.location.href = path;
    }

    ExportarExcel(tabela: string, worksheet?: string){
      TableToExcel(tabela, worksheet);
    }

    JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean){
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
    }

    MudarTema(temaEscuro: boolean){
        this.app.temaEscuro = temaEscuro;
        this.sessionApp.dados.temaEscuro = temaEscuro;
        localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        this.$vuetify.theme.dark = temaEscuro;
    }

    TrocarModulo(modulo: string){
        this.$router.push({name: modulo});
    }

    GetAppVersion(){
        const pack = require('../../../../package.json');
        return pack.version;
    }

    //GED
    GEDProduto(){
        return process.env.NODE_ENV == 'development' ? "C:/GED/GestaoPedidoImportacao/Produto" : "/produto";
    }

    GEDProdutoCliente(clienteId: number, produtoId: number, codigo: string){
        return process.env.NODE_ENV == 'development' ? `C:/GED/GestaoPedidoImportacao/Cliente/${clienteId}/ProdutoFoto/${produtoId}/${codigo}` : "/cliente/${clienteId}/ProdutoFoto/${produtoId}/${codigo}";
    }
}