import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class MOQService extends Service {
    
    constructor() {
        super('v1/moq');
    }

    public CalcularMOQ(valor: number, agendamentoId?: number){
        return http.get(`${this.GetNomeControle()}/CalcularMOQ`, {
            params: {
                valor,
                agendamentoId
            }
        });
    }
}