import { Pessoa, Produto, ProdutoClienteFoto, TipoEmbalagem, UnidadeMedida } from ".";

export class ProdutoCliente {

    pessoaId: number = 0;
    pessoa!: Pessoa;
    produtoId: number = 0;
    produto!: Produto;
    codigo: string = "";
    descricao: string = "";
    marca: string = "";
    ean: string = "";
    eanInner: string = "";
    eanMaster: string = "";
    ncm: string = "";
    cxInner: number = 0;
    cxMaster: number = 0;
    tipoEmbalagemId?: number = 0;
    tipoEmbalagem!: TipoEmbalagem;
    departamento: string = "";
    codigoInterno: string = "";
    cubagemUnitaria: number = 0;
    observacao: string = "";
    testReport: string = "";
    hsCode: string = "";
    unidadeMedidaId?: number;
    unidadeMedida!: UnidadeMedida;
    ativo: boolean = true;

    constructor(model?: ProdutoCliente){

        if(!model)
            return;

        this.pessoaId = model.pessoaId;
        this.pessoa = model.pessoa;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
        this.codigo = model.codigo;
        this.descricao = model.descricao;
        this.marca = model.marca;
        this.ean = model.ean;
        this.eanInner = model.eanInner;
        this.eanMaster = model.eanMaster;
        this.ncm = model.ncm;
        this.cxInner = model.cxInner;
        this.cxMaster = model.cxMaster;
        this.tipoEmbalagemId = model.tipoEmbalagemId;
        this.tipoEmbalagem = model.tipoEmbalagem;
        this.departamento = model.departamento;
        this.codigoInterno = model.codigoInterno;
        this.cubagemUnitaria = model.cubagemUnitaria;
        this.observacao = model.observacao;
        this.testReport = model.testReport;
        this.hsCode = model.hsCode;        
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.ativo = model.ativo;
    }
}