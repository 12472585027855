

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PageBase } from '@/core/models/shared';

@Component
export default class RelatorioPage extends PageBase {
    @Prop() titulo!: string;
    @Prop() subTitulo!: string;
    @Prop() marcaDagua?: string;
    @Prop({default: true}) fonteVariavel!: boolean;
    @Prop() paisagem!: boolean;
    @Prop({default: true}) overlay!: boolean;
    @Prop({default: true}) hasCabecalho!: boolean;
    @Prop() exportarExcel!: boolean;
    @Prop({default: 100}) fonteInicial!: number;
    @Prop({default: 0.5}) margem!: number ;
    @Prop({default: null}) pagina!: number[];

    root: any = document.documentElement;    

    created(){
        
    }

    mounted(){
        this.root = document.body;
        if(this.pagina == null){
            if(this.paisagem){
                this.root.style.setProperty("--widthPage", 29.7 + 'cm');
                this.root.style.setProperty("--min-heightPage", 19 + 'cm');
                this.setPageSize('landscape')
            }      
            else{
                this.root.style.setProperty("--widthPage", 21 + 'cm');
                this.root.style.setProperty("--min-heightPage", 26 + 'cm');
                this.setPageSize('portrait')
            }
        }
        else{
            this.root.style.setProperty("--widthPage", this.pagina[0] + 'cm');
            this.root.style.setProperty("--min-heightPage", this.pagina[1] + 'cm');
        }
        // INICIALIZAR FONT SIZE
        document.documentElement.style.setProperty("--fontSize", this.fonteInicial + '%');
        document.documentElement.style.setProperty("--page-margem", this.margem + 'cm');
    }

    setPageSize(size){
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${size}}`;
        document.head.appendChild(style);
    }

    AumentarFonte(){
        var raiz = getComputedStyle(this.root);
        var valor = parseFloat(raiz.getPropertyValue('--fontSize').replace("%", ""));
        valor += 5;
        document.documentElement.style.setProperty("--fontSize", valor + '%');
    }

    DiminuirFonte(){
        var raiz = getComputedStyle(this.root);
        var valor = parseFloat(raiz.getPropertyValue('--fontSize').replace("%", ""));
        valor -= 5;
        document.documentElement.style.setProperty("--fontSize", valor + '%');
    }

    Imprimir(){
        window.print();
    }

    Fechar(){
        window.close();
    }
    
}
