import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class ProdutoService extends Service {
    
  constructor() {
    super('v1/produto');
  }

  private GetFiltro(parametros: string, filter: any){

    if (filter) {
      let paramTemp = '';

      if(filter.codigoChina){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `Contains(codigoChina, '${filter.codigoChina}')`;
      }

      if(filter.codigo){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `Contains(codigo, '${filter.codigo}')`;
      }

      if(filter.anoInicial){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `ano ge ${filter.anoInicial}`;
      }

      if(filter.anoFinal){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `ano le ${filter.anoFinal}`;
      }

      if(filter.fobInicial){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `fob ge ${filter.fobInicial}`;
      }

      if(filter.fobFinal){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `fob le ${filter.fobFinal}`;
      }

      if(filter.categoriaId){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `categoriaId eq ${filter.categoriaId}`;
      }

      if(filter.embalagemId){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `tipoEmbalagemId eq ${filter.embalagemId}`;
      }

      if(filter.funcionamentoId){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `funcionamentoId eq ${filter.funcionamentoId}`;
      }

      if(filter.situacao != null){
        if(paramTemp){paramTemp += ' and '; }
        paramTemp += `ativo eq ${filter.situacao}`;
      }

      if (paramTemp) {

        if(parametros){
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }
    }
  
    return parametros;  
  }

  public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
    let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
    parametros = this.GetFiltro(parametros, filter);
    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }
  
  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q,
      },
      headers: httpHeader.headers
    });
  }
  
  //IMPORTACAO PARA ATUALIZACAO DE BASE DE PRODUTOS
  public Importar(file: any){
    let bodyFormData = new FormData();
    bodyFormData.append('arquivo', new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), file.name);

    return http.post(`${this.GetNomeControle()}/Importacao`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    });
  }

  public ExportarAtualizacao(codigos: string[]){
    return http.post(`${this.GetNomeControle()}/ExportarAtualizacao`, codigos, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/octet-stream',
        'Access-Control-Allow-Origin': '*'
      }
    });
  }

  public ImportarAtualizacao(file: any){
    let bodyFormData = new FormData();
    bodyFormData.append('arquivo', new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), file.name);

    return http.post(`${this.GetNomeControle()}/ImportarAtualizacao`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    });
  }

  public Etiquetas(codigos: string) {
    return http.get(`${this.GetNomeControle()}/Etiquetas`, {
      params: {
        codigos
      },
      headers: httpHeader.headers
    });
  }

  //GED
  public ImportarFoto(file: any){
    let bodyFormData = new FormData();
    bodyFormData.append('arquivo', new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), file.name);

    return http.post(`${this.GetNomeControle()}/Foto`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    });
  }
  CarregarFoto(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/Foto`);
  }
  GravarFoto(id: number, tipo: string, nome: string, arquivo: any){
    let bodyFormData = new FormData();
    var blob = new Blob([arquivo], { type: tipo });
    bodyFormData.append('arquivo', blob, nome);
    return http.post(`${this.GetNomeControle()}/${id}/Foto`, bodyFormData, {
      headers: {'Content-Type': 'multipart/form-data' }
    });
  }
  ExcluirFoto(id: number){
    return http.delete(`${this.GetNomeControle()}/${id}/Foto`);
  }  
}