import { Embarque, PedidoItem, Pessoa } from ".";

export class EmbarqueItem implements Shared.IEntity{

    id: number = 0;
    embarqueId: number = 0;
    embarque!: Embarque;
    itemId: number = 0;
    item!: PedidoItem;
    fabricanteId?: number;
    fabricante!: Pessoa;
    quantidade: number = 0;

    quantidadeEmbarqueAntigo!: number;

    //METRICAS
    carton: number = 0;
    cbmCtnCxMaster: number = 0;    
    cbg: number = 0;
    valor: number = 0;
    pedidoOriginal: number = 0;
    totalItem: number = 0;
    ajusteContainer: number = 0;

    constructor(model?: EmbarqueItem){

        if(!model)
            return;

        this.id = model.id;
        this.embarqueId = model.embarqueId;
        this.embarque = model.embarque;
        this.itemId = model.itemId;
        this.item = model.item;
        this.fabricanteId = model.fabricanteId;
        this.fabricante = model.fabricante;
        this.quantidade = model.quantidade;
        
        //METRICAS
        this.carton = model.carton;
        this.cbmCtnCxMaster = model.cbmCtnCxMaster;        
        this.cbg = model.cbg;
        this.valor = model.valor;
        this.pedidoOriginal = model.pedidoOriginal;
        this.totalItem = model.totalItem;
        this.ajusteContainer = model.ajusteContainer;
    }
}