import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";
import { EmbarqueItem } from "@/core/models/geral";

export class EmbarqueService extends Service {
    
    constructor() {
        super('v1/embarque');
    }

    ItensSemEmbarque(pedidoId: number){
        return http.get(`${this.GetNomeControle()}/ItensSemEmbarque`, {
            params: {
                pedidoId
            }
        });
    }

    Metricas(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/Metricas`);
    }

    ItemMetricas(id: number, pedidoId: number, desmembrar: boolean, itens: any[]){
        return http.post(`${this.GetNomeControle()}/ItemMetricas`, itens, {
            params: {
                id,
                pedidoId,
                desmembrar
            }
        });
    }

    Desmembrar(id: number, idAntigo: number){
        return http.post(`${this.GetNomeControle()}/${id}/Desmembrar/${idAntigo}`);
    }

    //RELATORIOS
    RelatorioProformaInvoice(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/RelatorioProformaInvoice`);
    }

    public PackingList(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/PackingList`, {
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/octet-stream',
            'Access-Control-Allow-Origin': '*'
        }
        });
    }

    //CRONOGRAMA
    Cronograma(pedidoNumeroInicial?: number, pedidoNumeroFinal?: number, dataPedidoInicial?: string, dataPedidoFinal?: string, dataEmbarqueInicial?: string, dataEmbarqueFinal?: string){
        return http.get(`${this.GetNomeControle()}/Cronograma`, {
            params: {
                pedidoNumeroInicial,
                pedidoNumeroFinal,
                dataPedidoInicial,
                dataPedidoFinal,
                dataEmbarqueInicial,
                dataEmbarqueFinal                
            }
        });
    }
}