import Vue from 'vue';

//RECURSOS
import MasterPage from "./shared/MasterPage.vue"

import ModuloCard from "./shared/ModuloCard.vue";
import TextSearch from "./shared/TextSearch.vue";
import AlterarSenha from './shared/AlterarSenha.vue';
import Avatar from "./shared/Avatar.vue";
import BreadCrumb from "./shared/BreadCrumb.vue";
import ListaHeader from './shared/ListaHeader.vue';
import RelatorioPage from './shared/RelatorioPage.vue';
import StatusChip from "./shared/StatusChip.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
import BarCode from "vue-barcode"

Vue.component("master-page", MasterPage);

Vue.component("modulo-card", ModuloCard);
Vue.component("text-search", TextSearch);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("avatar-component", Avatar);
Vue.component("breadCrumb-component", BreadCrumb);
Vue.component("lista-header", ListaHeader);
Vue.component("relatorio-page", RelatorioPage);
Vue.component("status-chip", StatusChip);
Vue.component("stream-barcode", StreamBarcodeReader);
Vue.component("barcode", BarCode);
//

// CADASTRO
import CadastroAgendamento from "./geral/agendamento/Cadastro.vue"
import CadastroCertificado from "./geral/certificado/Cadastro.vue"
import CadastroContainer from "./geral/container/Cadastro.vue"
import CadastroPedido from "./geral/pedido/Cadastro.vue"
import CadastroPedidoItem from "./geral/pedido/CadastroItem.vue"
import CadastroPessoa from "./geral/pessoa/Cadastro.vue"
import CadastroPessoaProduto from "./geral/pessoa/CadastroProduto.vue"
import CadastroProduto from "./geral/produto/Cadastro.vue"

import CadastroEmbarque from "./geral/embarque/Cadastro.vue";
import CadastroEmbarqueItem from "./geral/embarque/CadastroItem.vue";

import EtiquetaProduto5 from "./shared/ProdutoEtiquetas/5Cm.vue"
import EtiquetaProduto10 from "./shared/ProdutoEtiquetas/10Cm.vue"

Vue.component("cadastro-agendamento", CadastroAgendamento);
Vue.component("cadastro-certificado", CadastroCertificado);
Vue.component("cadastro-container", CadastroContainer);
Vue.component("cadastro-pedido", CadastroPedido);
Vue.component("cadastro-pedido-item", CadastroPedidoItem);
Vue.component("cadastro-pessoa", CadastroPessoa);
Vue.component("cadastro-pessoa-produto", CadastroPessoaProduto);
Vue.component("cadastro-produto", CadastroProduto);

Vue.component("cadastro-embarque", CadastroEmbarque);
Vue.component("cadastro-embarque-item", CadastroEmbarqueItem);

Vue.component("etiqueta-produto-5", EtiquetaProduto5);
Vue.component("etiqueta-produto-10", EtiquetaProduto10);

//CADASTRO AUXILIAR
import CadastroCategoria from "./geral/categoria/Cadastro.vue"
import CadastroFunionamento from "./geral/funcionamento/Cadastro.vue"
import CadastroMOQ from "./geral/moq/Cadastro.vue"
import CadastroMecanismo from "./geral/mecanismo/Cadastro.vue"
import CadastroOrgaoCertificador from "./geral/orgaoCertificador/Cadastro.vue"
import CadastroTipoEmbalagem from "./geral/tipoEmbalagem/Cadastro.vue"
import CadastroUnidadeMedida from "./geral/unidadeMedida/Cadastro.vue"
import CadastroPorto from "./geral/porto/Cadastro.vue"
import CadastroTermoPagamento from "./geral/termoPagamento/Cadastro.vue"


Vue.component("cadastro-categoria", CadastroCategoria);
Vue.component("cadastro-funcionamento", CadastroFunionamento);
Vue.component("cadastro-moq", CadastroMOQ);
Vue.component("cadastro-mecanismo", CadastroMecanismo);
Vue.component("cadastro-orgaoCertificador", CadastroOrgaoCertificador);
Vue.component("cadastro-tipoEmbalagem", CadastroTipoEmbalagem);
Vue.component("cadastro-unidadeMedida", CadastroUnidadeMedida);
Vue.component("cadastro-porto", CadastroPorto);
Vue.component("cadastro-termoPagamento", CadastroTermoPagamento);
//