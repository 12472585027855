import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/home' },
  { path: '/auth', name: 'Auth', component: () => import('../views/shared/Auth.vue') },

  { path: '/home', name: 'Home', component: () => import('../views/shared/Home.vue') },

  { path: '/cadastro/agendamento', name: 'Agendamento', component: () => import('../views/geral/agendamento/Lista.vue') },
  { path: '/cadastro/certificado', name: 'Certificado', component: () => import('../views/geral/certificado/Lista.vue') },

  { path: '/cadastro/pedido', name: 'Pedido', component: () => import('../views/geral/pedido/Lista.vue') },
  { path: '/cadastro/pessoa', name: 'Pessoa', component: () => import('../views/geral/pessoa/Lista.vue') },
  { path: '/cadastro/produto', name: 'Produto', component: () => import('../views/geral/produto/Lista.vue') },

  { path: '/cadastro/embarque', name: 'Embarque', component: () => import('../views/geral/embarque/Lista.vue') },
  { path: '/detalhar/embarque/:id', name: 'DetalharEmbarques', component: () => import('../views/geral/embarque/Visualizar.vue') },
  { path: '/geral/embarque/relatorio/:id', name: 'DetalharPi', component: () => import('../views/geral/embarque/Pi1.vue') },
  { path: '/geral/embarque/relatorio2/:id', name: 'DetalharPi2', component: () => import('../views/geral/embarque/Pi2.vue') },
  { path: '/geral/embarque/relatorio3/:id', name: 'DetalharPi3', component: () => import('../views/geral/embarque/Pi3.vue') },
  { path: '/geral/embarque/relatorio4/:id', name: 'DetalharPi4', component: () => import('../views/geral/embarque/Pi4.vue') },
  
  { path: '/cadastroAuxiliar/categoria', name: 'Categoria', component: () => import('../views/geral/categoria/Lista.vue') },
  { path: '/cadastroAuxiliar/container', name: 'Container', component: () => import('../views/geral/container/Lista.vue') },
  { path: '/cadastroAuxiliar/cronogramaEtapa', name: 'Empresa Cronograma Etapa', component: () => import('../views/geral/empresaCronogramaEtapa/Lista.vue') },
  { path: '/cadastroAuxiliar/funcionamento', name: 'Funcionamento', component: () => import('../views/geral/funcionamento/Lista.vue') },
  { path: '/cadastroAuxiliar/mecanismo', name: 'Mecanismo', component: () => import('../views/geral/mecanismo/Lista.vue') },
  { path: '/cadastroAuxiliar/orgaoCertificador', name: 'OrgaoCertificador', component: () => import('../views/geral/orgaoCertificador/Lista.vue') },
  { path: '/cadastroAuxiliar/tipoEmbalagem', name: 'TipoEmbalagem', component: () => import('../views/geral/tipoEmbalagem/Lista.vue') },
  { path: '/cadastroAuxiliar/moq', name: 'MOQ', component: () => import('../views/geral/moq/Lista.vue') },
  { path: '/cadastroAuxiliar/unidadeMedida', name: 'UnidadeMedida', component: () => import('../views/geral/unidadeMedida/Lista.vue') },
  { path: '/cadastroAuxiliar/porto', name: 'Porto', component: () => import('../views/geral/porto/Lista.vue') },
  { path: '/cadastroAuxiliar/termoPagamento', name: 'TermoPagamento', component: () => import('../views/geral/termoPagamento/Lista.vue') },

  { path: '/relatorio/configuracao/cronograma', name: 'Cronograma', component: () => import('../views/geral/cronograma/Configuracao.vue') },
  { path: '/relatorio/cronograma', name: 'CronogramaRelatorio1', component: () => import('../views/geral/cronograma/Relatorio1.vue') },
  // { path: '/relatorio/cronograma/:id', name: 'CronogramaRelatorio2', component: () => import('../views/geral/cronograma/Relatorio2.vue') },

  { path: '/administrativo/selecaoProduto', name: 'SelecaoProduto', component: () => import('../views/administrativo/SelecaoProdutos/Lista.vue') },
  { path: '/administrativo/imprimirEtiquetas', name: 'ImprimirProdutoEtiquetas', component: () => import('../views/administrativo/ImprimirEtiquetas.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
